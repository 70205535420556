import React, { useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./CustomDatePicker.css";
import { DateRange } from "react-date-range";

function CustomDatePicker(props) {
  const [startDate, setStartDate] = useState(
    props.rangeValues.startDate
      ? new Date(props.rangeValues.startDate)
      : new Date()
  );
  const [endDate, setEndDate] = useState(
    props.rangeValues.endDate ? new Date(props.rangeValues.endDate) : new Date()
  );
  // eslint-disable-next-line
  const [startMinDate, setStartMinDate] = useState(new Date("01/01/2016"));

  const handleSelect = (ranges) => {
    // console.log([
    //   ranges.selection.startDate.toDateString(),
    //   ranges.selection.endDate.toDateString(),
    // ]);
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
    props.getRangeValues(
      ranges.selection.startDate.toDateString(),
      ranges.selection.endDate.toDateString()
    );
  };

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  return (
    <div>
      <div className="label-wrapper">
        <div className="row">
          <div className="column">Start Date:</div>
          <div className="column">End Date:</div>
        </div>
      </div>

      <DateRange
        ranges={[selectionRange]}
        onChange={handleSelect}
        minDate={startMinDate}
        maxDate={new Date()}
      />
    </div>
  );
}

export default CustomDatePicker;
