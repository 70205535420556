import { mapsNationalData } from '../Data/mapsNationalData'
import { flCountyData } from '../Data/flCountyJson'
import { flRegionCircuitJson } from '../Data/flRegionCircuitJson'

export const mapsFLData = {
  mapZoom: 2,
  reportMapZoom: 4,
  mapBoxCenter: [-81.760254, 27.994402],
  mapBoxCenterReport: [-81.760254, 27.994402],
  mapMaxBounds: [[-92.77287069367998, 22.7091], [-76.26493500455376, 32.743288738760796]],
  mapMaxBoundsReport: [[-92.77287069367998, 22.7091], [-76.26493500455376, 32.743288738760796]],
  reportMapWidth: '577px',

  'Services': {
    orgList: [
      { name: 'Case Management', color: 0 },
      { name: 'Crisis', color: 2 },
      { name: 'Daily Needs', color: 3 },
      { name: 'Education', color: 4 },
      { name: 'Employment', color: 5 },
      { name: 'Financial Services', color: 6 },
      { name: 'Healthcare', color: 7 },
      { name: 'Housing', color: 8 },
      { name: 'Legal Services', color: 9 },
      { name: 'Mental Health', color: 10 },
      { name: 'Peer-led Support', color: 11 },
      { name: 'Substance Abuse Services', color: 12 },
      { name: 'Training', color: 13 },
      { name: 'Translation Services', color: 14 },
      { name: 'Transportation', color: 15 },
      { name: 'Other', color: 16 },
    ],

    'Provider Location ': { // info based on filter selection
      'State': {
        mapJson: null,
        dataVar: 'orgList',
        locSourceVar: 'county',
        popUpLocName: 'County',
        popUpOrgTypeName: 'Service Provided',
        featureLocProp: 'COUNTY',
      },
      'County': {
        mapJson: flCountyData,
        dataVar: 'orgList',
        locSourceVar: 'county',
        popUpLocName: 'County',
        popUpOrgTypeName: 'Service Provided',
        featureLocProp: 'COUNTY',
      },
      'Region': {
        mapJson: flRegionCircuitJson,
        dataVar: 'orgList',
        locSourceVar: 'region',
        popUpLocName: 'Region',
        popUpOrgTypeName: 'Service Provided',
        featureLocProp: 'CIRCUIT',
      },
    },
    mapInfo: <div>
      <p className='margin0'><span className='fontWeight700'>Data Source:</span> TiP Lab, University of South Florida (USF)</p>
      <p className='margin0'><span className='fontWeight700'>Date:</span> 2024 - Present</p>

      <p>
        The Services Data is provided by the <a href='https://www.usf.edu/cbcs/criminology/tip-lab/bright/' rel='noopener noreferrer' target='_blank'>TiP Research Lab's BRIGHT Team</a>. BRIGHT is an online, vetted, and secure resource referral network for individuals seeking or providing services for those affected by trafficking. This map displays the locations and services of organizations across Florida that offer or may offer support to confirmed or potential victims of human trafficking. The organizations visualized either report their services to TiP Lab or participate in BRIGHT. Organizations with multiple locations or services may appear more than once. The map view can be filtered by both the Provider Location filter and the Legend.
        <br></br><br></br>
        The data and visualizations in Lighthouse are provided as-is with no warranties. The information is intended to inform Lighthouse users about human trafficking; it is not intended to predict human trafficking cases, demonstrate the efficacy of solutions, or identify individual victims or survivors. By using this information, you consent to the permitted use terms of your Lighthouse agreement. Allies Against Slavery is not responsible for your reproduction or representation of the data in Lighthouse.
      </p>
    </div>
  }, // Services

  'Federal Cases': {
    mapZoom: mapsNationalData.mapZoom,
    reportMapZoom: mapsNationalData.reportMapZoom,
    mapBoxCenter: mapsNationalData.mapBoxCenter,
    mapBoxCenterReport: mapsNationalData.mapBoxCenterReport,
    mapMaxBounds: mapsNationalData.mapMaxBounds,
    mapMaxBoundsReport: mapsNationalData.mapMaxBoundsReport,
    reportMapWidth: mapsNationalData.reportMapWidth,
    'Map View': mapsNationalData['Federal Cases']['Map View'],
    mapInfo: mapsNationalData['Federal Cases'].mapInfo,
  },
  'State Policy': {
    mapZoom: mapsNationalData.mapZoom,
    reportMapZoom: mapsNationalData.reportMapZoom,
    mapBoxCenter: mapsNationalData.mapBoxCenter,
    mapBoxCenterReport: mapsNationalData.mapBoxCenterReport,
    mapMaxBounds: mapsNationalData.mapMaxBounds,
    mapMaxBoundsReport: mapsNationalData.mapMaxBoundsReport,
    reportMapWidth: mapsNationalData.reportMapWidth,
    'Data': mapsNationalData['State Policy']['Data'],
    'CriminalData': mapsNationalData['State Policy']['CriminalData'],
    mapInfo: mapsNationalData['State Policy'].mapInfo
  }
}