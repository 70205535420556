import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import '../Filter.css';
import { withStyles } from '@material-ui/core/styles';
import { policies } from "../../../../Data/policyList"

const BlackCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '20px',
    fontWeight: '300',
    overflowY: 'scroll',
    height: '400px',
    width: '500px'
  },
  filterWrapper: {
    display: 'flex'
  },
  active: {
    cursor: 'pointer'
  },
  inactive: {
    fontWeight: '400 !important',
    cursor: 'pointer'
  },
  checkboxLabel: {
    padding: '0px'
  },
  checkboxes: {
    marginLeft: '20px',
    display: 'flex',
    flexDirection: 'column'
  },
  saveButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '20px'
  }
}));

export default function PolicyFilter(props) {
  const classes = useStyles();
  const [filterSelections, setFilterSelections] = React.useState([]);
  const [chapterSelections, setChapterSelections] = React.useState([]);

  const handleCheckboxChange = (e, item) => {
    if (filterSelections.includes(item)) {
      let newArray = filterSelections.filter(x => x !== item);
      setFilterSelections(newArray);

      let newChapters = []
      policies.forEach(itemA => {
        if (itemA.values.every(x => newArray.includes(x))) {
          if (newChapters.includes(itemA.name)) {
            newChapters = [...chapterSelections].filter(x => x !== itemA.name)
          }
        }
      })
      setChapterSelections(newChapters)
    } else {
      let newArray = [...filterSelections]
      let filteredArray = newArray.filter(x => x !== "All policies");
      filteredArray.push(item);
      setFilterSelections(filteredArray);

      let newChapters = [...chapterSelections]
      policies.forEach(itemA => {
        if (itemA.values.every(x => filteredArray.includes(x))) {
          if (!newChapters.includes(itemA.name)) {
            newChapters.push(itemA.name)
          }
        }
      })
      setChapterSelections(newChapters)
    }
  }


  const handleAllCheckboxChange = (e) => {
    if (e.target.checked) {
      setFilterSelections(["All policies"]);
      setChapterSelections([])
    } else {
      setFilterSelections([]);
    }
  }

  const handleChapterCheckboxChange = (e, itemA) => {
    if (chapterSelections.includes(itemA.name)) {
        let newChapters = [...chapterSelections].filter(x => x !== itemA.name)
      setChapterSelections(newChapters)

      let newArray = [...filterSelections]
      itemA.values.forEach(itemB => (
        newArray = newArray.filter(x => x !== itemB)
      ))
      // console.log('itemA.values', itemA.values)
      setFilterSelections(newArray);
    } else {
      let newChapters = [...chapterSelections]
      newChapters.push(itemA.name)
      setChapterSelections(newChapters)

      let newArray = [...filterSelections]
      let filteredArray = newArray.filter(x => x !== "All policies");
      itemA.values.forEach(itemB => {
        filteredArray.push(itemB);
      })
      setFilterSelections(filteredArray);
    }
  }

  const handleSubmit = () => {
    if (typeof filterSelections === 'string') {
      props.filterCallback({
        name: 'Policy',
        filter: 'State Policy',
        selection: filterSelections,
        value: [filterSelections],
        stateLocation: 'TX'
      })
    } else {
      props.filterCallback({
        name: 'Policy',
        filter: 'State Policy',
        selection: filterSelections,
        value: filterSelections,
      })

    }

    props.handleClose()
  }

  useEffect(() => {
    for (let i = 0; i < props.selectedFilters.length; i++) {
      if (props.selectedFilters[i].name === 'Policy' && props.selectedFilters[i].filter === 'State Policy') {
        setFilterSelections(props.selectedFilters[i].value)
        let newChapters = [...chapterSelections]
        policies.forEach(itemA => {
          if (itemA.values.every(x => props.selectedFilters[i].value.includes(x))) {
            newChapters.push(itemA.name)
          }
        })
        setChapterSelections(newChapters)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classes.container}>
      <div className={classes.checkboxes}>
        <FormControlLabel className="checkboxLabel"
          control={
            <BlackCheckbox
              checked={filterSelections.includes("All policies")}
              onChange={e => handleAllCheckboxChange(e, "All policies")} name="checkedA" />
          }
          label={"All policies"}
        />
        {policies.map((policy, keyA) => {
          return (
            <div key={keyA} style={{ display: 'flex', flexDirection: 'column' }}>
              <div className='flexRowCenter'>
                <FormControlLabel key={keyA} className="checkboxLabel"
                  control={
                    <BlackCheckbox
                      checked={chapterSelections.includes(policy.name)}
                      onChange={e => handleChapterCheckboxChange(e, policy)} name="checkedA" />
                  }
                  label={<p style={{ fontSize: '16px', fontWeight: '500', margin: 0 }}><strong>{policy.name}</strong></p>}
                />
                {/* <p style={{ fontSize: '16px', fontWeight: '500' }}><strong>{offense.name}</strong></p> */}
              </div>
              {policy.values.map((item, keyB) => {
                return (
                  <div style={{ paddingLeft: 30 }}>
                    <FormControlLabel key={keyB} className="checkboxLabel"
                      control={
                        <BlackCheckbox
                          checked={filterSelections.includes(item)}
                          onChange={e => handleCheckboxChange(e, item)} name="checkedA" />
                      }
                      label={item}
                    />
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>

      <div className={classes.saveButton}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Save
          </Button>
      </div>
    </div>
  )
}