import React, { useState, useRef, useEffect } from "react";
import { useAppContext } from '../../../Lib/UserContext'
import * as d3 from "d3";
import { Checkbox } from '../../../Components/Checkbox'

export const WhiskerPlot = (props) => {
    const wrapperRef = useRef();
    const svgRef = useRef();
    const [activeKeys, setActiveKeys] = useState(props.activeKeys)
    const [activeYaxisRight, setActiveYaxisRight] = useState(props.activeKeys.includes(props.yaxisRight.source))
    const { chartColorArray } = useAppContext()
    // const colors = d3.scaleOrdinal(chartColorArray)

    const legendItemClick = (e, itemSource) => {
        let activeKeysCopy = [...activeKeys]
        if (activeKeysCopy.includes(itemSource)) {
            activeKeysCopy = activeKeysCopy.filter(s => s !== itemSource)
        } else {
            activeKeysCopy.push(itemSource)
        }
        setActiveKeys(activeKeysCopy)
    }

    const yaxisRightLegendClick = (e) => {
        setActiveYaxisRight(!activeYaxisRight)
    };


    useEffect(() => {
        function endTooltip() {
            const boxes = document.querySelectorAll('.tooltip');

            boxes.forEach(box => {
                box.style.opacity = 0;
            });
        }
        window.addEventListener('scroll', endTooltip)
        //Setting the width and height
        var margin = { top: 20, right: props.yaxisRight ? 80 : 0, bottom: 80, left: 80 };
        var width;
        var height = 500;
        if (window.innerWidth > 1200 && window.innerWidth < 1440) {
            width = 900;
        } else if (window.innerWidth >= 1440 && window.innerWidth < 1920) {
            width = 900;
        } else {
            width = 1200;
        }
        if (props.widthNeeded) {
            width = props.widthNeeded;
        } else if (props.report) {
            width = 700
        }

        // Formatting xaxis titles and bar colors
        let titles = props.data.map(item => item.xaxis)

        //For reports
        var data = props.data;

        if (data.length < 5) {
            width = 600;
        }

        //Calculating axis
        let max = d3.max(data, (d) => +(d3.max(d.outliers, (o) => o))) ?? 0
        if (max === 0) {
            max = 5
        }
        let max2 = props.yaxisRight ? d3.max(data, (d) => +d?.[props.yaxisRight?.source]) ?? 0 : null
        if (max2 === 0) {
            max2 = 5
        }

        //Selecting the svg element and removing past svg's (from filter)
        let svg = d3.select(svgRef.current);
        svg.selectAll("svg").remove();

        //Creating SVG
        var graphSvg = svg
            .append("svg")
            .attr("width", width)
            .attr("height", height)
            .append("g")
            .attr("transform", "translate(" + 0 + "," + 0 + ")");

        // Creating the tooltip
        var tooltip = d3
            .select(wrapperRef.current)
            .append("div")
            .style("opacity", 0)
            .attr("class", "tooltip")
            .style("background-color", "white")
            .style("color", "black")
            .style("border", "solid")
            .style("border-width", "2px")
            .style("border-radius", "5px")
            .style("position", "fixed")
            .style("z-index", "-1")
            .style("padding", "5px");
        tooltip.append("div").attr("class", "title");
        tooltip.append("div").attr("class", "label");
        tooltip.append("div").attr("class", "percent");

        // set the ranges
        var x = d3
            .scaleBand()
            .domain(d3.range(data.length))
            .range([margin.left, width - margin.right])
            .padding(0.1);
        var y = d3
            .scaleLinear()
            .domain([0, max])
            .nice()
            .range([height - margin.bottom, margin.top]);
        var y2;
        if (props.yaxisRight) {
            y2 = d3
                .scaleLinear()
                .domain([0, max2])
                .nice()
                .range([height - margin.bottom, margin.top]);
        }

        var xAxis = (g) =>
            g.attr("transform", `translate(0,${(height - margin.bottom) + 12})`).call(
                d3
                    .axisBottom(x)
                    .tickFormat((i) => titles[i])
                    .tickSizeOuter(0)
            );

        var formatxAxis = d3.format(".0f");
        let yAxis = (g) =>
            g
                .attr("transform", `translate(${max < 20 ? 60 : 80},0)`)
                .call(max < 20 ? d3.axisLeft(y).ticks(max).tickFormat(formatxAxis) : d3.axisLeft(y).ticks(null, data.y))
                .attr("class", "y-axis")
                .call((g) =>
                    g
                        .append("text")
                        .attr("x", -margin.left)
                        .attr("y", 10)
                        .attr("text-anchor", "start")
                        .text(data.y)
                );

        let yAxis2;
        if (props.yaxisRight) {
            yAxis2 = (g) =>
                g
                    .attr("transform", `translate(${width - margin.right},0)`)
                    .call(max2 < 20 ? d3.axisRight(y2).ticks(max2).tickFormat(formatxAxis) : d3.axisRight(y2).ticks(null, data.y2))
                    .attr("class", `y-axis ${!activeYaxisRight ? 'transparent' : ''}`)
                    .call((g) =>
                        g
                            .append("text")
                            .attr("x", +margin.right)
                            .attr("y", 10)
                            .attr("text-anchor", "start")
                            .text(data.y2)
                    )
                    .attr('opacity', activeYaxisRight ? 1 : 0);
        }



        // Show the main vertical line
        graphSvg
            .selectAll("vertLines")
            .data(data)
            .enter()
            .append("line")
            .attr("x1", (d, i) => x(i) + (x.bandwidth() / 2))
            .attr("x2", (d, i) => x(i) + (x.bandwidth() / 2))
            .attr("y1", (d) => y(d.min))
            .attr("y2", (d) => y(d.max))
            .attr("stroke-width", 2)
            .attr("stroke", "black")

        // rectangle for the main box
        let boxWidth = 90

        // Creating the median boxes
        graphSvg
            .selectAll("medianBoxes")
            .data(data)
            .enter()
            .append("rect")
            .on("mouseover", (d) => onMouseOver(d.median, 'median'))
            .on("mouseout", onMouseOut)
            .on("mousemove", onMouseMove)
            .attr("x", (d, i) => (x(i) + (x.bandwidth() / 2)) - boxWidth / 2)
            .attr("y", (d, i) => (y(d.q3)))
            .attr("height", (d, i) => (y(d.q1) - y(d.q3)))
            .attr("width", boxWidth)
            .attr("stroke-width", 2)
            .attr("stroke", 'black')
            .style("fill", chartColorArray[0])

        // Show the median lines
        if (activeKeys.includes('median')) {
            graphSvg
                .selectAll("medianLines")
                .data(data)
                .enter()
                .append("line")
                .attr("x1", (d, i) => x(i) + ((x.bandwidth() - boxWidth) / 2))
                .attr("x2", (d, i) => (x(i) + x.bandwidth()) - ((x.bandwidth() - boxWidth) / 2))
                .attr("y1", (d) => y(d.median))
                .attr("y2", (d) => y(d.median))
                .attr("stroke-width", 2)
                .attr("stroke", "black")
                .style("width", x.bandwidth())
        }

        // // Show min and max (lower, upper whiskers) horizontal lines
        let hLinesWidth = 50

        data.forEach((d, mainIndex) =>
            graphSvg
                .selectAll("whiskers")
                .data([{ num: d.min, var: 'min' }, { num: d.max, var: 'max' }])
                .enter()
                .append("line")
                .on("mouseover", (n) => onMouseOver(n.num, n.var))
                .on("mouseout", onMouseOut)
                .on("mousemove", onMouseMove)
                .attr("x1", x(mainIndex) + ((x.bandwidth() - hLinesWidth) / 2))
                .attr("x2", (x(mainIndex) + x.bandwidth()) - ((x.bandwidth() - hLinesWidth) / 2))
                .attr("y1", (n) => y(n.num))
                .attr("y2", (n) => y(n.num))
                .attr("stroke-width", 2)
                .attr("stroke", "black")
        )

        // Creating the mean and mode boxes
        let smallBoxWidth = 15
        let smallBoxHeight = 13

        if (activeKeys.includes('mean')) {
            graphSvg
                .selectAll("meanBoxes")
                .data(data)
                .enter()
                .append("rect")
                .on("mouseover", (d) => onMouseOver(d.mean, 'mean'))
                .on("mouseout", onMouseOut)
                .on("mousemove", onMouseMove)
                .attr("x", (d, i) => (x(i) + (x.bandwidth() / 2)) - smallBoxWidth / 2)
                .attr("y", (d, i) => y(d.mean) - (smallBoxHeight / 2))
                .attr("height", smallBoxHeight)
                .attr("width", smallBoxWidth)
                .attr("rx", '2px')
                .attr("ry", '2px')
                .style("fill", (d, i) => chartColorArray[1])
        }

        if (activeKeys.includes('mode')) {
            graphSvg
                .selectAll("modeBoxes")
                .data(data)
                .enter()
                .append("rect")
                .on("mouseover", (d) => onMouseOver(d.mode, 'mode'))
                .on("mouseout", onMouseOut)
                .on("mousemove", onMouseMove)
                .attr("x", (d, i) => (x(i) + (x.bandwidth() / 2)) - smallBoxWidth / 2)
                .attr("y", (d, i) => y(d.mode) - (smallBoxHeight / 2))
                .attr("height", smallBoxHeight)
                .attr("width", smallBoxWidth)
                .attr("rx", '2px')
                .attr("ry", '2px')
                .style("fill", (d, i) => chartColorArray[2])
        }

        // Add outlier circles
        if (activeKeys.includes('outlier')) {
            data.forEach((d, mainIndex) =>
                graphSvg
                    .selectAll("outliers")
                    .data(d.outliers)
                    .enter()
                    .append("circle")
                    .on("mouseover", (n) => onMouseOver(n, 'outlier'))
                    .on("mouseout", onMouseOut)
                    .on("mousemove", onMouseMove)
                    .attr("cx", (n, i) => x(mainIndex) + (x.bandwidth() / 2)) // x(i) - circleWidth / 2 + Math.random() * circleWidth)
                    .attr("cy", (n, i) => y(n)) // y(d))
                    .attr("r", 5)
                    .style("fill", "white")
                    .attr("stroke-width", 1.5)
                    .attr("stroke", "black")
            )
        }


        // Adding the bars
        if (props.yaxisRight && activeYaxisRight) {
            let barWidth = 60

            graphSvg
                .append("g")
                .selectAll("rect")
                .data(data)
                .join("rect")
                .on("mouseover", (d) => onMouseOver(d[props.yaxisRight.source], props.yaxisRight.source))
                .on("mouseout", onMouseOut)
                .on("mousemove", onMouseMove)
                .attr("x", (d, i) => x(i) + ((x.bandwidth() - barWidth) / 2))
                .attr("width", barWidth) // x.bandwidth()
                .attr("y", (d) => {
                    return y2(d[props.yaxisRight.source]);
                })
                .attr("height", (d) => {
                    return y2(0) - y2(d[props.yaxisRight.source]); // ??
                })
                .attr("fill", (d, i) => {
                    return chartColorArray[props.keys.findIndex(k => k.variable === props.yaxisRight.source)]
                })
                .attr('opacity', 0.7);
        }


        function onMouseOver(value, dataSourceName, customHoverName) {
            tooltip.style("opacity", 1)
            tooltip.style("z-index", "9999");

            let title = props.keys.filter(k => k.variable === dataSourceName)?.[0]?.name ?? customHoverName ?? ''

            tooltip.select(".title").text(title);
            tooltip
                .select(".label")
                .text(`Total: ${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`);
        }

        function onMouseOut() {
            tooltip.style("opacity", 0)
                .style("z-index", "-1");
        }

        function onMouseMove(d) {
            tooltip
                .style("opacity", 1)
                .style("z-index", "9999")
                .style("top", d3.event.clientY - 100 + "px")
                .style("left", d3.event.clientX - 150 + "px");
        }

        // x axis labels
        graphSvg
            .append("g")
            .attr("class", "x axis")
            .attr("transform", `translate(0, ${height})`)
            .call(xAxis)
            .selectAll(".tick text")
            .style("font-size", props.xaxisFontSize ? props.xaxisFontSize : '12px')
            .call(wrap, x.bandwidth());


        graphSvg.append("g").call(yAxis);

        if (props.yaxisRight && activeYaxisRight) {
            graphSvg.append("g").call(yAxis2);
        }


        // text label for the x axis
        graphSvg
            .append("text")
            .attr("class", "axislabel")
            .attr("transform", `translate(${(width / 2)}, ${height - 18})`)
            .style("text-anchor", "middle")
            .text(props.xaxis);

        // text label for the y axis
        graphSvg
            .append("text")
            .attr("class", "axislabel")
            .attr("transform", "rotate(-90)")
            .attr("y", 0)
            .attr("x", 0 - height / 2)
            .attr("dy", "1em")
            .style("text-anchor", "middle")
            .text(props.yaxis);

        if (props.yaxisRight) {
            graphSvg
                .append("text")
                .attr("class", "axislabel")
                .attr("transform", "rotate(-90)")
                .attr("y", width - 20)
                .attr("x", -(height / 2))
                .attr("dy", "1em")
                .style("text-anchor", "middle")
                .text(props.yaxisRight && activeYaxisRight ? props.yaxisRight.text : '');
        }

        // wrap x axis labels function
        function wrap(text, width) {
            text.each(function () {
                var text = d3.select(this),
                    words = text.text().split(/[ /]+/).reverse(), // /\s+/
                    word,
                    line = [],
                    lineNumber = 0,
                    lineHeight = 1.1, // ems
                    y = text.attr("y"),
                    dy = 0.5, // parseFloat(text.attr("dy")),
                    tspan = text
                        .text(null)
                        .append("tspan")
                        .attr("x", 0)
                        .attr("y", y)
                        .attr("dy", dy + "em");
                while ((word = words.pop())) {
                    line.push(word);
                    tspan.text(line.join(" "));
                    if (tspan.node().getComputedTextLength() > width && line.length > 1) {
                        line.pop();
                        tspan.text(line.join(" "));
                        line = [word];
                        tspan = text
                            .append("tspan")
                            .attr("x", 0)
                            .attr("y", y)
                            .attr("dy", ++lineNumber * lineHeight + dy + "em")
                            .text(word);
                    }
                }
            });
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        props.data,
        props.keys,
        activeKeys,
        props.report,
        props.widthNeeded,
        activeYaxisRight,
        props.xaxis,
        props.yaxis,
        props.yaxisRight,
        props.type,
    ]);

    return (
        <div id={props.id} className={props.smalltext ? "graph" : "graph1"}>
            <div ref={wrapperRef} style={{ position: "relative" }}>
                <div ref={svgRef} />
            </div>

            <div className='fields' style={{ display: "flex", flexWrap: "wrap" }}>
                {props.keys.map((k, i) => (
                    <div style={{ position: 'relative' }}>
                        {k.name === 'Outlier' ?
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '20px' }}>
                                <div style={{ cursor: props.report ? 'default' : 'pointer', marginRight: '8px' }} onClick={(e) => props.report ? console.log('') : legendItemClick(e, 'outlier')}>
                                    {activeKeys.includes('outlier') ?
                                        <div style={{padding: '1.5px'}}>
                                            <div style={{ width: '14px', height: '14px', border: '1.5px solid black', borderRadius: '100%' }} />
                                        </div>
                                        :
                                        <div style={{ width: '17px', height: '17px', borderRadius: '4px', border: '1px solid #0000008a' }}></div>
                                    }
                                </div>
                                <span style={{ fontSize: '12px', fontWeight: 600 }}>{k.name}</span>
                            </div>
                            :
                            <Checkbox
                                text={k.name}
                                checked={k.diffActiveState ? activeYaxisRight : activeKeys.includes(k.variable)}
                                hideCheckmarkWhenChecked={k.diffActiveState ? false : true}
                                onClickFunc={k.diffActiveState ? yaxisRightLegendClick : legendItemClick}
                                onClickFuncItem={k.diffActiveState ? null : k.variable}
                                // cursorDefault={k.diffActiveState ? false : true}
                                color={k.diffActiveState ?
                                    (activeYaxisRight ? chartColorArray[i] : 'transparent')
                                    :
                                    (activeKeys.includes(k.variable) ? chartColorArray[i] : 'transparent')
                                }
                                // colorWhenFalse={k.name === 'Median'}
                                report={props.report}
                            />
                        }

                        {k.name === 'Median' && activeKeys.includes('median') &&
                            <div style={{ width: '17px', height: '2px', backgroundColor: 'black', position: 'absolute', top: '9.5px', left: 0 }} />
                        }
                    </div>
                ))}
            </div>
        </div>
    );
};
