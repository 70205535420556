/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef } from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import { useAppContext } from "../../Lib/UserContext";
import { availableDashboards } from '../../Data/availableDashboards'
import { ServicesTXLayer } from "./MapLayers/ServicesTXLayer";
import { ServicesLayer } from "./MapLayers/ServicesLayer";
import { ScreeningsLayer } from "./MapLayers/ScreeningsLayer";
import { CareCoordinationLayer } from "./MapLayers/CareCoordinationLayer";
import { PublicSafetyLayer } from "./MapLayers/PublicSafetyLayer";
import { TDCJLayer } from "./MapLayers/TDCJLayer";
import { DemandLayer } from "./MapLayers/DemandLayer";
import { NewsLayer } from "./MapLayers/NewsLayer";
import { HotlineLayer } from "./MapLayers/HotlineLayer";
import CommunityRiskLegend from "./MapLayers/Legends/CommunityRiskLegend";
import { CommunityRiskLayer } from "./MapLayers/CommunityRiskLayer";
import { FederalCasesLayer } from "./MapLayers/FederalCasesLayer";
import { StatePolicyLayer } from "./MapLayers/StatePolicyLayer";
import { StatePolicyCriminalLayer } from "./MapLayers/StatePolicyCriminalLayer";
import { ImbLayer } from "./MapLayers/ImbMapLayer";
import { ImbZipLayer } from "./MapLayers/ImbMapLayerHEX";
import { TraffickingLocationLayer } from "./MapLayers/TraffickingLocationLayer";

import MapCheckboxesLegend from "./MapLayers/Legends/MapCheckboxesLegend";
import ScreeningsLegend from "./MapLayers/Legends/ScreeningsLegend";
import TraffickingLocationsLegend from "./MapLayers/Legends/TraffickingLocationsLegend";

import CareCoordinationLegend from "./MapLayers/Legends/CareCoordinationLegend";
import PublicSafetyLegend from "./MapLayers/Legends/PublicSafetyLegend";
import FederalCasesLegend from "./MapLayers/Legends/FederalCasesLegend";
import StatePolicyLegend from "./MapLayers/Legends/StatePolicyLegend";
import TDCJLegend from "./MapLayers/Legends/TDCJLegend";
import DemandLegend from "./MapLayers/Legends/DemandLegend";
import NewsLegend from "./MapLayers/Legends/NewsLegend";
import ImbLegend from "./MapLayers/Legends/ImbLegend";
import HotlineLegend from "./MapLayers/Legends/HotlineLegend";
//import BusinessesLegend from "./MapLayers/Legends/BusinessesLegend";
import Button from "@material-ui/core/Button";
// import InfoIcon from "@material-ui/icons/Info";
import InfoIcon from '../../Assets/Images/map-info-copy.png'
// eslint-disable-next-line no-unused-vars
import AddToQueueIcon from "@material-ui/icons/AddToQueue";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { taHubData } from "../../Data/taHub";
//import { businessData } from "../../Data/ImbDataJson";

mapboxgl.accessToken =
  "pk.eyJ1IjoiYWNhZG1pbiIsImEiOiJja2F6a2s4d2YwMHl1MndwcGpqcnRjaTJjIn0.QG6URDtAvSAacVoj_8-Xsw";

export default function Map(props) {
  const mapContainer = useRef(null);
  const { stateLocation, chartColorArray } = useAppContext();
  let mapsIndex = availableDashboards.filter(d => d.stateLocation === stateLocation)?.[0]?.mapsIndex
  const [openDefinition, setDefinitionOpen] = React.useState(false);
  const [statePolicyMap, setStatePolicyMap] = React.useState('All Others');

  const [servicesLegendItems, setServicesLegendItems] = useState(mapsIndex['Services']?.orgList?.map((org, i) => { return { name: org.name, color: chartColorArray[org.color], checked: true } }));

  const handleDefinitionOpen = () => {
    setDefinitionOpen(true);
  };

  const handleDefinitionClose = () => {
    setDefinitionOpen(false);
  };

  const servicesLegendClick = (items) => {
    // console.log(items);
    setServicesLegendItems(items);
  };


  const mapLoader = () => {
    var map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/light-v10",
      center: props.report ? (mapsIndex?.[props.charts]?.mapBoxCenterReport ?? mapsIndex?.mapBoxCenterReport) : (mapsIndex?.[props.charts]?.mapBoxCenter ?? mapsIndex?.mapBoxCenter),
      zoom: props.report ? (mapsIndex?.[props.charts]?.reportMapZoom ?? mapsIndex?.reportMapZoom) : (props.mapZoom || (mapsIndex?.[props.charts]?.mapZoom ?? mapsIndex?.mapZoom)),
      maxBounds: props.report ? (mapsIndex?.[props.charts]?.mapMaxBoundsReport ?? mapsIndex?.mapMaxBoundsReport) : (mapsIndex?.[props.charts]?.mapMaxBounds ?? mapsIndex?.mapMaxBounds),
      attributionControl: false,
      preserveDrawingBuffer: true,
    });
    map.scrollZoom.disable();
    var nav = new mapboxgl.NavigationControl();
    map.addControl(nav, "bottom-right");

    if (props.charts === "Services" && stateLocation === "TX") {
      ServicesTXLayer(map, props.filters, props.data, servicesLegendItems, props.report, chartColorArray);
    } else if (props.charts === "Services") {
      ServicesLayer(
        stateLocation,
        map,
        props.filters,
        props.data,
        servicesLegendItems,
        props.report,
        mapsIndex,
        chartColorArray
      );
    }
    if (props.charts === "Care Coordination") {
      CareCoordinationLayer(map, props.filters, props.data, props.report, stateLocation, chartColorArray);
    }
    if (props.charts === "Screenings") {
      ScreeningsLayer(map, props.filters, props.data, stateLocation);
    }
    if (props.charts === "Law Enforcement") {
      PublicSafetyLayer(map, props.filters, props.data, stateLocation);
    }
    if (props.charts === "Federal Cases") {
      FederalCasesLayer(map, props.filters, props.data, stateLocation, mapsIndex, props.filterCallback);
    }
    if (props.charts === "State Policy") {
      for (let i = 0; i < props.filters.length; i++) {
        if (props.filters[i].name === 'Map View' && props.filters[i].filter === 'State Policy' && props.filters[i].value?.[0] === 'All Other Trafficking Policy') {
          setStatePolicyMap('All Others')
          StatePolicyLayer(map, props.filters, props.data, stateLocation, mapsIndex);
        } else if (props.filters[i].name === 'Map View' && props.filters[i].filter === 'State Policy' && props.filters[i].value?.[0] !== 'All Other Trafficking Policy') {
          setStatePolicyMap('Not All Others')
          StatePolicyCriminalLayer(map, props.filters, props.data, stateLocation, mapsIndex);
        }
      }
    }
    if (props.charts === "Texas Department of Criminal Justice") {
      TDCJLayer(map, props.filters, props.data);
    }
    if (props.charts === "Demand") {
      DemandLayer(map, props.filters, props.data, stateLocation);
    }
    if (props.charts === "Hotline") {
      HotlineLayer(map, props.filters, props.data, stateLocation);
    }
    if (props.charts === "News") {
      NewsLayer(map, props.filters, props.data, props.report, chartColorArray);
    }
    if (props.charts === "Community Risk") {
      CommunityRiskLayer(map, props.filters, props.data);
    }
    if (props.charts === "Businesses of Interest") {
      for (let i = 0; i < props.filters.length; i++) {
        if (props.filters[i].name === 'Location ' && props.filters[i].filter === 'Businesses of Interest' && props.filters[i].value?.[0] === 'Zip Code') {
          ImbZipLayer(map, props.filters, props.data, stateLocation);
        } else if (props.filters[i].name === 'Location ' && props.filters[i].filter === 'Businesses of Interest' && props.filters[i].value !== 'Zip Code') {
          ImbLayer(map, props.filters, props.data, stateLocation);
        }
      }
    }
    if (props.charts === "Locations") {
      TraffickingLocationLayer(map, props.filters, props.data);
    }
  }


  useEffect(() => { // dashboard map
    if (!props.report) {
      mapLoader()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.data,
    props.filters,
    servicesLegendItems
  ]);


  useEffect(() => { // report page map
    if (props.report) {
      mapLoader()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  // eslint-disable-next-line no-unused-vars
  const addToReportPage = () => {
    props.addToReportPage(`${props.charts} Map`, props.charts);
    // props.addToReportPage(`${props.charts} Map`, props.charts, "Map");
  };
  return (
    <div className={props.report ? 'relative' : ''}>
      {/* {props.charts === "Care Coordination" ? (
        <div className={props.report ? "casemapinfo reportMapInfoBox" : "casemapinfo notReport">
          <h2>Care Coordination</h2> */}
      {/* {props.data?.total ? (
            <div>
              <h4>
                {Number(props.data?.total)
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </h4>
            </div>
          ) : null}
        </div>
                ) : null} */}

      {props.charts === "Screenings" ? (
        <div className={props.report ? "casemapinfo reportMapInfoBox" : "casemapinfo notReport"}>
          <h2>Screenings</h2>
          {props.data?.countiesTotal ? (
            <div>
              <p className='totalText'>Total: {props.data?.countiesTotal?.total?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
              <h4>
                {`Clear Concern:
                ${props.data?.countiesTotal?.clearConcern?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                (${props.data?.countiesTotal?.clearConcernPercent}%)`}
              </h4>
              <h4>
                {`Possible Concern:
                ${props.data?.countiesTotal?.possibleConcern?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                (${props.data?.countiesTotal?.possibleConcernPercent}%)`}
              </h4>
              <h4>
                {`No Concern:
                ${props.data?.countiesTotal?.noConcern?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                (${props.data?.countiesTotal?.noConcernPercent}%)`}
              </h4>
            </div>
          ) : (
              <div>
                <p className='totalText'>Total: 0</p>
                <h4>Clear Concern: 0 (0%)</h4>
                <h4>Possible Concern: 0 (0%)</h4>
                <h4>No Concern: 0 (0%)</h4>
              </div>
            )}
        </div>
      ) : null}
      {props.charts === "Law Enforcement" ? (
        <div className={props.report ? "casemapinfo reportMapInfoBox" : "casemapinfo notReport"}>
          <h2>Total Cases</h2>
          {props.data?.countiesTotal ? ( //grab the correct variable name from the backend using the console
            <div>
              {/* trial */}
              {/* <h4>
                {`No Concern:
                    ${props.data?.countiesTotal?.[1]?.noconcern
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} (${(
                  (Number(props.data?.countiesTotal?.[1]?.noconcern) /
                    (Number(props.data?.countiesTotal?.[0]?.clearconcern) +
                      Number(props.data?.countiesTotal?.[2]?.possibleconcern) +
                      Number(props.data?.countiesTotal?.[1]?.noconcern))) *
                  100
                )?.toFixed(1)}%)`}
              </h4> */}
              <span>
                Total:{" "}
                {Number(props.data?.countiesTotal)
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </span>
            </div>
          ) : null}
        </div>
      ) : null}
      {/* {props.charts === "Federal Cases" &&
        props.filters?.filter(f => f.name === 'Location ' && f.filter === ('Federal Cases'))?.[0].value?.[0] === 'District Prosecuted' ? (
          <div className={props.report ? "casemapinfo reportMapInfoBox" : "casemapinfo notReport"}>
            <h2>Federal District Courts</h2>
            {props.data?.districtsTotals ? (
              <div>
                {stateLocation === 'National' &&
                  <></>
                }
                {stateLocation === 'TX' &&
                  <h4>
                    Northern: {props.data?.districtsTotals?.filter(x => x?.district_prosecuted === 'Northern District of Texas')?.[0]?.total}
                    <br></br>
                  Southern: {props.data?.districtsTotals?.filter(x => x?.district_prosecuted === 'Southern District of Texas')?.[0]?.total}
                    <br></br>
                  Eastern: {props.data?.districtsTotals?.filter(x => x?.district_prosecuted === 'Eastern District of Texas')?.[0]?.total}
                    <br></br>
                  Western: {props.data?.districtsTotals?.filter(x => x?.district_prosecuted === 'Western District of Texas')?.[0]?.total}
                  </h4>
                }
                {stateLocation === 'LA' &&
                  <h4>
                    Eastern: {props.data?.districtsTotals?.filter(x => x.district_prosecuted === 'Eastern District of Louisiana')?.[0]?.total}
                    <br></br>
                  Middle: {props.data?.districtsTotals?.filter(x => x.district_prosecuted === 'Middle District of Louisiana')?.[0]?.total}
                    <br></br>
                  Western: {props.data?.districtsTotals?.filter(x => x.district_prosecuted === 'Western District of Louisiana')?.[0]?.total}
                  </h4>
                }
              </div>
            ) : null}
          </div>
        ) : null
      } */}

      {props.charts === "Federal Cases" &&
        <>
        {props.filters?.filter(f => f.name === 'Map View' && f.filter === ('Federal Cases'))?.[0]?.value[0] === 'Trafficking Locations' && (
          <div className={props.report ? "casemapinfo reportMapInfoBox" : 'casemapinfo notReport'}>
            <h2>Total Locations</h2>

            {props.data?.[mapsIndex['Federal Cases']['Map View']['Trafficking Locations']?.totalDataVar] ? (
              <div className='fontMulish fontWeight500'>
                <span>
                  Total:{" "}
                  {Number(props.data?.[mapsIndex['Federal Cases']['Map View']['Trafficking Locations']?.totalDataVar][0]?.count)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </span>
              </div>
            ) : null}
          </div>
        )}

        {props.filters?.filter(f => f.name === 'Map View' && f.filter === ('Federal Cases'))?.[0].value?.[0] === 'State Prosecuted' && (
        <>
          {props.data?.stateClickedData ?
              <div className={props.report ? "casemapinfo reportMapInfoBox" : "casemapinfo notReport"}>
                <h2>{props.data?.stateClickedData?.name}</h2>

              <div className='fontMulish fontWeight500'>
                  <h3 className='fontSize1-5em lineHeight1-4em fontWeight600 margin0 marginB0-66em'>{props.data?.stateClickedData?.totalCasesProsecuted?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h3>
                  <p className='fontSize1em lineHeight1-5em margin0 marginB1em'>Cases of human trafficking prosecuted in the state</p>
                  <h3 className='fontSize1-5em lineHeight1-4em fontWeight600 margin0 marginB0-66em'>{props.data?.stateClickedData?.totalTraffCases?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h3>
                  <p className='fontSize1em lineHeight1-5em margin0 marginB1em'>Cases of human trafficking that had a trafficking location in the state</p>
                  <h3 className='fontSize1-5em lineHeight1-4em fontWeight600 margin0 marginB0-66em'>{props.data?.stateClickedData?.defendentTotal?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} : {props.data?.stateClickedData?.victimTotal?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h3>
                  <p className='fontSize1em lineHeight1-5em margin0'>The number of defendants and victims in cases prosecuted in the state</p>
              </div>

            </div>
            :
              <div className={props.report ? "casemapinfo reportMapInfoBox" : "casemapinfo notReport"}>
                <h2>
                  {mapsIndex['Federal Cases']['Map View']['State Prosecuted']?.mapInfoBox?.title}
                </h2>

                <div className='fontMulish fontWeight500'>
                  {props.data?.[mapsIndex['Federal Cases']['Map View']['State Prosecuted']?.totalDataVar] && mapsIndex['Federal Cases']['Map View']['State Prosecuted']?.mapInfoBox?.info?.map((obj, i) => {
                    let num = '';
                    if (obj.calcType === 'addEachObj') {
                      num = props.data?.[obj.dataVar]?.map(d => Number(d[obj.sourceVar]))?.reduce((a, c) => {
                        return a + c
                      }, 0) ?? ''

                    } else {
                      num = props.data?.[obj.dataVar] ? Number(props.data?.[obj.dataVar]?.[0][obj.sourceVar])?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''
                    }
                    return (
                      <p
                        key={i}
                        className={`${obj?.className ?? ''}`}
                        style={obj?.style}>{obj?.label} {num}</p>
                  )
                })}
              </div>
            </div>
          }
        </>
          )}
        </>
      }


      {
        props.charts === "State Policy" && statePolicyMap === 'All Others' ? (
          <div className={props.report ? "casemapinfo reportMapInfoBox" : "casemapinfo notReport"}>
              <h2>{mapsIndex['State Policy']['Data']?.mapInfoBox?.title}</h2>
              <div>
              <span>Total: {props.data?.totalPolicies?.total}</span>
              {/* ?.filter( item => {
                  if (Number(item.b_year) === year) {
                    return item;
                  }
                })[0] */}
              </div>
            </div>

          ) : null
      }
       {
        props.charts === "State Policy" && statePolicyMap !== 'All Others' ? (
          <div className={props.report ? "casemapinfo reportMapInfoBox" : "casemapinfo notReport"}>
              <h2>{mapsIndex['State Policy']['CriminalData']?.mapInfoBox?.title}</h2>
              <div>
              <span>Total: {props.data?.criminalSentencingAverage?.[0].total}</span>
              </div>
            </div>

          ) : null
      }

      {
        props.charts === "Texas Department of Criminal Justice" ? (
          <div className={props.report ? "casemapinfo reportMapInfoBox" : "casemapinfo notReport"}>
            <h2>Total Inmates</h2>
            {props.data?.countiesTotal ? (
              <div>
                <span>
                  Total:{" "}
                  {Number(props.data?.countiesTotal?.[0]?.count)
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </span>
              </div>
            ) : null}
          </div>
        ) : null
      }
      {
        props.charts === "Demand" ? (
          <div className={props.report ? "casemapinfo reportMapInfoBox" : "casemapinfo notReport"}>
            <h2>Total Ads</h2>
            {props.data?.countiesTotal ? (
              <div>
                <span>
                  Total:{" "}
                  {Number(props.data?.countiesTotal?.[0]?.count)
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </span>
              </div>
            ) : null}
          </div>
        ) : null
      }
      {
        props.charts === "News" ? (
          <div className={props.report ? "casemapinfo reportMapInfoBox" : "casemapinfo notReport"}>
            <h2>Total News Incidents</h2>
            {taHubData ? (
              <div>
                <h4>
                  {Number(taHubData?.features?.length)
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </h4>
              </div>
            ) : null}
          </div>
        ) : null
      }
      {
        props.charts === "Hotline" ? (
          <div className={props.report ? "casemapinfo reportMapInfoBox" : "casemapinfo notReport"}>
            <h2>Hotline</h2>
            {props.data?.total > 0 ? (
              <div>
                <p className={`${(props.filters
                  ?.filter((f) => f.name === "Cases" && f.filter === "Hotline")?.[0]
                  ?.value?.includes("Involves Minors")) || (props.filters?.filter((f) => f.name === "Cases" && f.filter === "Hotline")?.[0]?.value?.includes("Involves Adults")) ? 'totalText' : ''}`}>
                  Total:{" "}
                  {Number(props.data?.total)
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </p>
                {props.filters?.filter((f) => f.name === "Cases" && f.filter === "Hotline")?.[0]?.value?.includes("Involves Minors") && (
                    <h4>
                      Involves Minors:{" "}
                      {props.data?.counties?.filter((item) => item.minors)
                        ?.reduce((accumulator, object) => {
                          return accumulator + Number(object.minors);
                        }, 0)
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </h4>
                  )}
                {props.filters?.filter((f) => f.name === "Cases" && f.filter === "Hotline")?.[0]?.value?.includes("Involves Adults") && (
                    <h4>
                      Involves Adults:{" "}
                      {props.data?.counties?.filter((item) => item.adults)
                        ?.reduce((accumulator, object) => {
                          return accumulator + Number(object.adults);
                        }, 0)
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </h4>
                  )}
              </div>
            ) : null}
          </div>
        ) : null
      }
      {/* Total Businesses of Interest  */}
      {
        props.charts === "Businesses of Interest" ? (
          <div className={props.report ? "casemapinfo reportMapInfoBox" : "casemapinfo notReport"}>
            <h2>Businesses</h2>
            {props.data?.countiesTotal ? (
              <div>
                <h4>
                  Total Businesses:{" "}
                  {stateLocation === 'LA' ?  Number(props.data?.parishesTotal?.[0]?.sum)
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : Number(props.data?.countiesTotal?.[0]?.sum)
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </h4>
              </div>
            ) : null}
          </div>
        ) : null
      }

      {
        props.charts === "Community Risk" ? (
          <div className={props.report ? "casemapinfo reportMapInfoBox" : "casemapinfo notReport"}>
            <h2>Community Risk</h2>
            {props.data?.stateAverageRisk ? (
              <div>
                <h4>
                  Statewide Risk Score:{" "}
                  {Number(props.data?.stateAverageRisk)?.toFixed(2)}
                </h4>
                <h4>
                  Total Population:{" "}
                  {Number(props.data?.countiesTotalPop)
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </h4>
                <h4>
                  Youth Population:{" "}
                  {Number(props.data?.countiesTotalYouthPop)
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </h4>
              </div>
            ) : null}
          </div>
        ) : null
      }
      {
        props.charts === "Locations" ? (
          <div className={props.report ? "casemapinfo reportMapInfoBox" : "casemapinfo notReport"}>
            <h2>Locations</h2>
            <div>
              <h4>
                Total Locations:{" "}
                {Number(props.data?.parishTotal)
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </h4>
            </div>
          </div>
        ) : null
      }
      <div
        className="mapInfoWrapper"
        style={{ display: props.report ? "none" : "flex" }}
      >
        <div>
          <Button
            style={{ backgroundColor: "white" }}
            onClick={handleDefinitionOpen}
          >
            {/* <InfoIcon /> */}
            <img src={InfoIcon} alt='i' style={{ width: '24px', height: '24px'}} />
          </Button>
        </div>
        <div style={{ marginLeft: "10px" }}>
          <Button
            style={{ backgroundColor: "white" }}
            onClick={addToReportPage}
          >
            <AddToQueueIcon style={{ width: '24px', height: '24px' }} />
          </Button>
        </div>
      </div>
      <div
        className="mapWrapper"
        style={{
          height: props.report ? "auto" : "80vh",
          width: props.report ? (mapsIndex?.[props.charts]?.reportMapWidth ?? mapsIndex?.reportMapWidth) : "100%",
        }}
      >
        <div
          className={`mapContainer ${props.report && 'report'}`}
          ref={mapContainer}
          style={{
            height: props.report ? "400px" : `calc(100vh - 64px)`,
            marginTop: props.report ? "0" : "64px",
          }}
        ></div>
      </div>
      {/* Legend */}
      <div>
        {props.charts === "Services" && (
          <MapCheckboxesLegend shouldUseNewStyles={props.shouldUseNewStyles} title='Services' legendItems={servicesLegendItems} handleLegendClick={servicesLegendClick} report={props.report} />
        )}

        {props.charts === "Care Coordination" ? (
          <CareCoordinationLegend shouldUseNewStyles={props.shouldUseNewStyles} report={props.report} chartColorArray={chartColorArray} />
        ) : null}

        {props.charts === "Screenings" ? (
          <ScreeningsLegend shouldUseNewStyles={props.shouldUseNewStyles} report={props.report} />
        ) : null}
        {props.charts === "Law Enforcement" ? (
          <PublicSafetyLegend report={props.report} state={stateLocation}/>
        ) : null}
        {props.charts === "Federal Cases" ? (
          <FederalCasesLegend shouldUseNewStyles={props.shouldUseNewStyles} report={props.report} state={stateLocation} filters={props.filters} />
        ) : null}
        {props.charts === "State Policy" ? (
          <StatePolicyLegend report={props.report} state={stateLocation} filters={props.filters} />
        ) : null}
        {props.charts === "Texas Department of Criminal Justice" ? (
          <TDCJLegend shouldUseNewStyles={props.shouldUseNewStyles} report={props.report} />
        ) : null}
        {props.charts === "Demand" ? (
          <DemandLegend report={props.report} />
        ) : null}
        {props.charts === "News" ? (
          <NewsLegend shouldUseNewStyles={props.shouldUseNewStyles} report={props.report} />
        ) : null}
        {props.charts === "Hotline" ? (
          <HotlineLegend shouldUseNewStyles={props.shouldUseNewStyles} report={props.report} />
        ) : null}

        {props.charts === "Businesses of Interest" ? (
          <ImbLegend shouldUseNewStyles={props.shouldUseNewStyles} report={props.report} />
        ) : null}

        {props.charts === "Community Risk" ? (
          <CommunityRiskLegend shouldUseNewStyles={props.shouldUseNewStyles} report={props.report} />
        ) : null}
        {props.charts === "Locations" ? (
          <TraffickingLocationsLegend shouldUseNewStyles={props.shouldUseNewStyles} report={props.report} />
        ) : null}
      </div>

      <Dialog
        className={`${props.shouldUseNewStyles ? "public-site-map-dialog" : ''} mapInfoDialog addRadius24`}
        onClose={handleDefinitionClose}
        aria-labelledby="customized-dialog-title"
        open={openDefinition}
        maxWidth={"lg"}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleDefinitionClose}
          className='fontSize24 fontWeight700'
        >
          Map Information
        </DialogTitle>
        <DialogContent>
          {props.charts === "Services" && stateLocation === "TX" ? (
            <div>
              <p className='margin0'><span className='fontWeight700'>Data Source:</span> CSTT Grantee Data</p>
              <p className='margin0 marginB14'><span className='fontWeight700'>Date:</span> 2018-2023</p>

              <p className='margin0'><span className='fontWeight700'>Data Source:</span> National Referral Directory Data</p>
              <p className='margin0'><span className='fontWeight700'>Date:</span> 2022-present</p>

              <p>
                <strong>CSTT Grantee Data</strong>
              </p>
              <p>
                The CSTT Grantee data source represents measures that grantees report to the Office of the Texas Governor Child Sex Trafficking Team, including state and federal measures. Historically, grantee data was refreshed quarterly. CSTT Grantee Data is not available after Q3 FY23.{" "}
              </p>
              <p>
                Quarters correspond to the fiscal year set by the Office of the Governor:
                <br /> Q1 = October-December, Q2 = January-March, Q3 = April-June, Q4 = July-September
              </p>
              <p>
                Some grantee measures have changed over time and may lack historical data. A zero in some fields may correspond to “no data” rather than the value zero.
              </p>
              <p>
                Some pinned locations on the map are approximate and may not match the physical locations of services or organizations. Some organizations are mapped to the county seat rather than a street or city location. Some organizations may be mapped to a headquarters address if there are multiple locations; this may make data appear skewed to a particular location (such as Travis County, for state-wide organizations which have headquarters in Austin).
              </p>
              <p>Services are defined as follows:</p>
              {/* <p>
                <strong>Bringing Justice</strong>: Justice programs provide
                trauma-informed resources and training to criminal justice
                partners, empower victim participation in investigations and
                prosecutions, shift cultural norms from blaming victims to
                holding exploiters accountable, and combat emerging methods of
                exploitation.
              </p> */}
              <p>
                <strong>CSEY Advocacy</strong>: Commercially Sexually Exploited Youth (CSEY) advocacy programs provide crisis intervention, ongoing case management, and supportive trust-based relationships that help survivors heal and thrive.
              </p>
              <p>
                <strong>Drop-In Center</strong>: Drop-In Center programs provide for immediate needs, including safety planning, crisis counseling, and opportunities for engagement in services for youth who are likely victims of sex trafficking.
              </p>
              <p>
                <strong>Emergency Shelter</strong>: Emergency placements are available 24/7 to provide survivors with physical safety, safety planning, and immediate trauma-responsive case management to meet their basic needs.
              </p>
              {/* <p>
                <strong>Innovative Services</strong>: Innovative Services are
                programs and initiatives, such as data and technology, training,
                and collaborative solutions that advance outcomes for victims
                and survivors.
              </p> */}
              <p>
                <strong>Juvenile Justice</strong>: Juvenile justice programs provide trauma-informed resources and training to criminal justice partners, empower victim participation in investigations and prosecutions, shift cultural norms from blaming victims to holding exploiters accountable, and combat emerging methods of exploitation.
              </p>
              <p>
                <strong>Long Term Residential</strong>: Residential treatment programs provide survivors with trauma-responsive case management, wraparound services, and 24-hour clinical and behavioral crisis services.
              </p>
              {/* <p>
                <strong>Prevention</strong>: Prevention programs provide
                research-based, age-appropriate prevention education and
                targeted interventions to reduce the vulnerability of high-risk
                youth, and equip adults with the knowledge and skills to
                recognize risks and respond effectively.
              </p> */}
              {/* <p>
                <strong>Therapy/Counseling</strong>: Therapy and counseling
                provide assistance and treatment in resolving personal, social,
                or psychological problems and difficulties.
              </p>
              <p>
                <strong>Other</strong>: Other community-based programs and
                services support individuals at risk for or experiencing
                exploitation.
              </p> */}
              {/* <br></br> */}
              {/* <p><strong>OVC/OTIP Grantee Data</strong></p>
                <p>The OVC/OTIP Grantee data source represents <a href="https://www.acf.hhs.gov/sites/default/files/documents/otip/OVC%20and%20OTIP%20Grantees%20%28April%202021%29.pdf" target="_blank" rel="noopener noreferrer">Human Trafficking Services Grantees</a> in Texas from the Department of Justice <a href="https://ovc.ojp.gov/funding/awards/list?subtopic=5111&state_served=TX" target="_blank" rel="noopener noreferrer">Office for Victims of Crime (OVC)</a> and the U.S. Department of Health & Human Services <a href="https://www.acf.hhs.gov/otip/grants" target="_blank" rel="noopener noreferrer">Office on Trafficking in Persons (OTIP)</a>. Grantees have pins on the map in all counties served by their awards.</p> */}
              <br></br>
              <p>
                In addition to CSTT grantees, the map may include non-grantees, such as organizations screening with CSE-IT who are not funded by the CSTT. To view grantees only, use the Time filter and select one or more fiscal years. For example, if fiscal year 2021 is selected, the map will show organizations who received CSTT funding between October 1, 2020 and September 30, 2021.
              </p>
              <br></br>

              <p>
                <strong>National Referral Directory </strong>
                <p>
                  The National Referral Directory represents the current Texas
                  service providers listed in the{" "}
                  <a
                    href="https://humantraffickinghotline.org/training-resources/referral-directory"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    public, online version of the National Referral Directory
                  </a>{" "}
                  from{" "}
                  <a
                    href="https://polarisproject.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Polaris
                  </a>
                  . The directory is used for the{" "}
                  <a
                    href="https://humantraffickinghotline.org/"
                    target="blank"
                    rel="noopener noreferrer"
                  >
                    National Human Trafficking Hotline
                  </a>{" "}
                  referral response. In addition to having a designated mandate,
                  program, funding, or staff to serve trafficking survivors, the
                  providers listed have elected to be included publicly on the
                  National Human Trafficking Hotline's online referral resources
                  page. The Public Directory also includes non-victim services
                  for organizations and community members trying to get involved
                  in the movement or those seeking training (these are meant to
                  have a human trafficking focus). Because not all areas have
                  anti-trafficking providers, the internal version of the
                  Directory, accessible by hotline advocates, includes
                  non-trafficking organizations who have been identified as
                  willing and able to serve survivors. These providers may focus
                  on other vulnerable populations or nexus crimes, such as
                  immigrants, refugees, homeless/RHY and DV/SA, or be general
                  social service providers. As organizations who do not focus on
                  trafficking as well as providers who are trafficking specific
                  but elected to remain private are not included in this list,
                  these additional resources should be accessed by calling the
                  National Human Trafficking Hotline. Some organizations may
                  appear more than once on the map, for example if they are both
                  CSTT grantees and in the National Referral Directory.
                  {/* referral response. The list includes anti-trafficking
                organizations, non-trafficking organizations who have been
                identified as willing and able to serve survivors, and
                non-victim services for organizations and community members
                trying to get involved in the movement or those seeking
                training. Note that not all of the organizations on this list
                appear in the{" "}
                <a
                  href="https://humantraffickinghotline.org/training-resources/referral-directory"
                  target="blank"
                  rel="noopener noreferrer"
                >
                  public version of the Directory
                </a>
                , either by choice or by not meeting the minimum criteria for
                that specific resource. */}
                </p>
              </p>


              <br></br>
              <hr />
            </div>
          ) : null}
          {props.charts === "Services" && stateLocation === "LA" ? (
            <div>
              <p className='margin0'><span className='fontWeight700'>Data Source:</span> Service Provider Data</p>
              <p className='margin0 marginB14'><span className='fontWeight700'>Date:</span> 2017-present</p>

              <p className='margin0'><span className='fontWeight700'>Data Source:</span> National Referral Directory Data</p>
              <p className='margin0'><span className='fontWeight700'>Date:</span> 2023-present</p>

              <p><strong>Service Providers</strong></p>

              <p>This data source represents human trafficking measures that service providers have reported annually to the Louisiana Department of Children and Family Services (DCFS), and more recently to the Louisiana Office of Human Trafficking Prevention (OHTP).</p>
              <p>Some measures have changed over time and may lack historical data. Some pinned locations on the map may not match the physical locations of services or organizations, and may be mapped to the parish seat rather than a street or city location (for example, if the organization has a P.O. Box or undisclosed location). Some organizations may be mapped to a headquarters address if there are multiple locations; this may make data appear skewed to a particular location.</p>

              <p><strong>National Referral Directory</strong></p>

              <p>The National Referral Directory represents the current Louisiana service providers listed in the <a href='https://humantraffickinghotline.org/training-resources/referral-directory' target='_blank' rel='noopener noreferrer'>public, online version of the National Referral Directory</a> from <a href='https://polarisproject.org/' target='_blank' rel='noopener noreferrer'>Polaris</a>. The directory is used for the <a href='https://humantraffickinghotline.org/' target='_blank' rel='noopener noreferrer'>National Human Trafficking Hotline</a> referral response. In addition to having a designated mandate, program, funding, or staff to serve trafficking survivors, the providers listed have elected to be included publicly on the National Human Trafficking Hotline's online referral resources page. The Public Directory also includes non-victim services for organizations and community members trying to get involved in the movement or those seeking training (these are meant to have a human trafficking focus). Because not all areas have anti-trafficking providers, the internal version of the Directory, accessible by hotline advocates, includes non-trafficking organizations who have been identified as willing and able to serve survivors. These providers may focus on other vulnerable populations or nexus crimes, such as immigrants, refugees, homeless/RHY and DV/SA, or be general social service providers. As organizations who do not focus on trafficking as well as providers who are trafficking specific but elected to remain private are not included in this list, these additional resources should be accessed by calling the National Human Trafficking Hotline. Some organizations may appear more than once on the map, for example if they are both Service Providers and in the National Referral Directory.</p>
            </div>
          ) : null}

          {props.charts === "Services" && stateLocation === "FL" &&
            <>
              {mapsIndex['Services'].mapInfo}
            </>
          }

          {props.charts === "Care Coordination" ? (
            <>
              {stateLocation === 'TX' ?
                <div>
                  <p className='margin0'><span className='fontWeight700'>Data Source:</span> Care Coordination Performance Measures</p>
                  <p className='margin0'><span className='fontWeight700'>Date:</span> 2018-2024</p>

                  <p>
                    This data source represents measures that Care Coordinators report to the Office of the Texas Governor Child Sex Trafficking Team, including measures submitted in Lighthouse starting in Q4 of fiscal year 2020. Historically, Care Coordination data was refreshed quarterly. Care Coordination Data is not available after Q2 FY24.
                  </p>
                  <p>
                    Quarters correspond to the fiscal year set by the Office of the Governor:
                <br />
                Q1 = October-December, Q2 = January-March, Q3 = April-June, Q4 = July-September
              </p>
                  <p>
                    Some Care Coordination measures were introduced in July, 2020 and lack historical data. A zero in some fields may correspond to “no data” rather than the value zero, until new data becomes available.
              </p>
                  <p>
                    Pins on the map represent care coordinators who are actively reporting quarterly measures.
              </p>
                </div>
                : // LA
                <div>
                  <p className='margin0'><span className='fontWeight700'>Data Source:</span> Care Coordination Performance Measures</p>
                  <p className='margin0'><span className='fontWeight700'>Date:</span> 2022-present</p>

                  <p>This data represents measures that Care Coordinators report quarterly by region. Quarters correspond to the calendar year. The measures include historic data from June 2022 through June 2023, and data submitted in Lighthouse for the reporting period starting July 2023. Some measures were introduced in January 2023 and lack historic data. A zero in some fields may correspond to “no data” rather than the value zero, until new data became available. There may be minor discrepancies in the data prior to July 2023.</p>
                  {/* <p>Quarters correspond to the fiscal year set by the Louisiana Office of Human Trafficking Prevention:</p>
                  <p>Q1 = July-September</p>
                  <p>Q2 = October-December</p>
                  <p>Q3 = January-March</p>
                  <p>Q4 = April-June</p> */}
                </div>
              }
            </>
          ) : null}
          {props.charts === "Screenings" ? (
            <div>
              {stateLocation === "TX" ? (
                <div>
                  <p className='margin0'><span className='fontWeight700'>Data Source:</span> WestCoast & TJJD CSE-IT screenings</p>
                  <p className='margin0 marginB14'><span className='fontWeight700'>Date:</span> 2016-present</p>

                  <p className='margin0'><span className='fontWeight700'>Data Source:</span> DFPS CSE-IT screenings</p>
                  <p className='margin0 marginB14'><span className='fontWeight700'>Date:</span> 2019-present</p>

                  <p className='margin0'><span className='fontWeight700'>Data Source:</span> Lighthouse CSE-IT Screenings</p>
                  <p className='margin0'><span className='fontWeight700'>Date:</span> 2020-present</p>

                  <p>
                    This data source represents CSE-IT screenings, including
                    historic data submitted by Texas agencies to WestCoast
                    Children’s Clinic, historic and current screenings from the
                    Texas Juvenile Justice Department (TJJD) and the Texas
                    Department of Family and Protective Services (DFPS), and
                    screenings submitted directly in Lighthouse starting in
                    2020.
                  </p>
                  <p>
                    Organizations may complete more than one CSE-IT screening for an individual as needed, or the same individual may be screened by more than one organization. Screening data should not be interpreted as prevalence data. Data represents the location where the screening occurred or the location of the agency that performed the screening. Some screenings may have occurred in locations that are different from the location of the agency that performed the screening.
                  </p>
                  <p>
                    TJJD CSE-IT screening data is submitted outside Lighthouse,
                    and is refreshed monthly. There may be a delay at the start
                    of each month before new data is visible.
                  </p>
                  <p>
                    DFPS CSE-IT screening data is submitted outside Lighthouse,
                    and may be delayed by several quarters before new data is
                    visible. Screenings are conducted in multiple Texas counties
                    (Bexar, Dallas, Harris, Tarrant, Travis), but are all
                    associated with Travis county within Lighthouse. DFPS data
                    reflects the number and trends for CSE-ITs completed by DFPS
                    and not the number of unique individual children's results.
                    DFPS may complete more than one CSE-IT for a child/youth as
                    needed, therefore children may be duplicated in this data
                    set. Once a child/youth has a CSE-IT clear concern score or
                    is an identified victim, DFPS no longer completes a CSE-IT.
                    A clear concern score does not equate to victimization but
                    is considered a presumed victim. DFPS partners who may also
                    complete CSE-ITs for children and youth involved with DFPS
                    are not included in the DFPS data set.
                  </p>
                  <p>
                    Historic CSE-IT screenings did not include certain data for
                    gender, identity and sexual orientation; these demographics
                    can be submitted in Lighthouse. Lighthouse screenings are
                    updated dynamically.
                  </p>
                  <p>Any screening values which are less than six will be shown as zero in the Screenings map and charts.</p>
                </div>
              ) : (
                  <div>
                    <p className='margin0'><span className='fontWeight700'>Data Source:</span> WestCoast screenings</p>
                    <p className='margin0 marginB14'><span className='fontWeight700'>Date:</span> 2020-present</p>

                    <p className='margin0'><span className='fontWeight700'>Data Source:</span> Lighthouse CSE-IT Screenings</p>
                    <p className='margin0'><span className='fontWeight700'>Date:</span> 2022-present</p>

                    <p>
                      This data source represents CSE-IT screenings, including
                      historic data submitted by Louisiana agencies to WestCoast
                      Children’s Clinic, and screenings submitted directly in
                      Lighthouse starting in 2022.
                  </p>
                    <p>
                      Organizations may complete more than one CSE-IT for an
                      individual as needed, or the same individual may be screened
                      by more than one organization. Data represents the location
                      where the screening occurred or the location of the agency
                      that performed the screening. Some screenings may have
                      occurred in locations that are different from the location
                      of the agency that performed the screening. Lighthouse
                      screenings are updated dynamically.
                  </p>
                    <p>Any screening values which are less than six will be shown as zero in the Screenings map and charts.</p>
                  </div>
                )}
            </div>
          ) : null}
          {props.charts === "Locations" ? (
            <div>
              <p className='margin0'><span className='fontWeight700'>Data Source:</span> Service Provider Data</p>
              <p className='margin0'><span className='fontWeight700'>Date:</span> 2017-present</p>

              <p>
                This data source represents human trafficking measures that
                service providers have reported annually to the Louisiana
                Department of Children and Family Services (DCFS), and more
                recently to the Louisiana Office of Human Trafficking Prevention
                (OHTP).
              </p>
              <p>Locations are defined as follows:</p>
              <ul>
                <li>
                  Parish of Trafficking - the parish in which trafficking
                  occurred
                </li>
                <li>
                  Parish of Origin - the parish the victim identifies as their
                  principal location
                </li>
              </ul>
            </div>
          ) : null}

          {props.charts === "Law Enforcement" && stateLocation === 'LA' && (
            <div>
              <p className='margin0'><span className='fontWeight700'>Data Source:</span> Louisiana State Police (LSP)</p>
              <p className='margin0'><span className='fontWeight700'>Date:</span> 2016-current</p>

              <p>
                <a
                  href="https://www.lsp.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Louisiana State Police (LSP)
                </a>{" "}
                data includes cases related to trafficking as reported by Louisiana law enforcement agencies. It has been extracted and filtered using the penal code chapters and offenses listed in Lighthouse. The data includes information about agencies (arrest and court), demographics, and offenses. Note that while arrest information is required to be reported, court data is voluntarily submitted; this may result in gaps in court-related data and visualizations. Certain offense codes may be grouped where similar; contact {" "}
                <a href="mailto:lighthouse@alliesagainstslavery.org">
                  lighthouse@alliesagainstslavery.org
                </a>{" "}
                for questions regarding grouped codes.
              </p>
            </div>
          )}

          {props.charts === "Law Enforcement" && stateLocation === 'TX' && (
            <div>
              <p className='margin0'><span className='fontWeight700'>Data Source: </span>Texas Office of the Attorney General (OAG)</p>
              <p className='margin0'><span className='fontWeight700'>Date: </span>2023-present</p>

              <p>
                Law enforcement data is provided by the Texas Office of the Attorney General (OAG) and includes data regarding state human trafficking arrests, as reported by Texas law enforcement agencies. It includes data for arrests pursuant to investigations under Chapter 20A (Trafficking of Persons) and Chapter 43 (Public Indecency) of the Texas Penal Code. Examples of offenses under Chapter 20A include labor trafficking, sex trafficking and continuous trafficking of persons. Examples of offenses under Chapter 43 include prostitution and obscenity.
                 </p>
              <p>
                This data has been extracted and filtered using the penal code chapters and offenses listed in Lighthouse. It includes information about arrest agencies, demographics, and offenses. Note that some Texas counties do not report arrest data; resulting in some gaps in the data. Certain offense codes may be grouped where similar; contact <a href="mailto:lighthouse@alliesagainstslavery.org">lighthouse@alliesagainstslavery.org</a> for questions regarding grouped codes.
              </p>
            </div>
          )}

          {props.charts === "Law Enforcement" && stateLocation === 'FL' && (
            <div>
              <p className='margin0'><span className='fontWeight700'>Data Source: </span>Florida Department of Law Enforcement (FDLE)</p>
              <p className='margin0'><span className='fontWeight700'>Date: </span>2021-present</p>

              <p>
                <a href="https://www.fdle.state.fl.us/About-Us" target="_blank" rel="noopener noreferrer">Florida Department of Law Enforcement (FDLE)</a> data includes cases related to trafficking as reported by Florida law enforcement agencies. It has been extracted and filtered using the penal code chapters and offenses listed in Lighthouse. The data includes information about arrest agencies, demographics, and offenses. Certain offense codes may be grouped where similar; contact <a href="mailto:lighthouse@alliesagainstslavery.org">lighthouse@alliesagainstslavery.org</a> for questions regarding grouped codes.
              </p>
            </div>
          )}

          {props.charts === "Federal Cases" &&
            <div className={'fontMulish'}>
              {mapsIndex['Federal Cases'].mapInfo}
            </div>
          }
          {props.charts === "State Policy" && statePolicyMap === 'All Others' &&
            <>
              {mapsIndex['State Policy'].mapInfo}
            </>
          }
          {props.charts === "State Policy" && statePolicyMap !== 'All Others' &&
            <>
              {mapsIndex['State Policy']['CriminalData'].mapInfo}
            </>
          }
          {props.charts === "Texas Department of Criminal Justice" ? (
            <div>
              <p className='margin0'><span className='fontWeight700'>Data Source:</span> Texas Department of Criminal Justice</p>
              <p className='margin0'><span className='fontWeight700'>Date:</span> 2006-current</p>

              <p>
                <a
                  href="https://www.tdcj.texas.gov/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Texas Department of Criminal Justice (TDCJ)
                </a>{" "}
                data includes information about inmates in Texas prisons with
                offenses related to trafficking. It has been extracted and
                filtered using the penal code chapters and offenses listed in
                Lighthouse. Data is obtained from the{" "}
                <a
                  href="https://data.texas.gov/browse?Dataset-Category_Category-Tile=Public+Safety"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Texas Open Data Portal
                </a>
                . The data includes information about inmate locations,
                sentences, and demographics. Certain offense codes have been
                grouped where similar; contact
                lighthouse@alliesagainstslavery.org for questions regarding the
                grouped codes. Initial data is based on a snapshot from June,
                2021.
              </p>
            </div>
          ) : null}
          {props.charts === "Demand" ? (
            <div>
              <p className='margin0'><span className='fontWeight700'>Data Source:</span> University of Alabama</p>
              <p className='margin0'><span className='fontWeight700'>Date:</span> 2023-current</p>

              <p>
                The <a href="https://ida.culverhouse.ua.edu/initiatives/human-trafficking/" target="_blank" rel="noopener noreferrer">University of Alabama Institute of Data and Analytics</a> extracts data from online advertisement websites that may be used to facilitate human trafficking. The data includes ad posts by location and site. Data is based on a 90 day snapshot that began July, 2023 and is updated quarterly. Ads are extracted by city and aggregated in Lighthouse. The map shows the total ads per county, based on the city location where the ad was posted. The map view can be filtered by the sites that post these ads. Due to ongoing web scraping blocks, one or more of these sites may be missing from the dataset in a particular snapshot and users may not be able to view them. Note that duplicate ad posts are common, as ads may be posted multiple times to stay visible in the queue.
              </p>
            </div>
          ) : null}
          {props.charts === "News" ? (
            <div>
              <p className='margin0'><span className='fontWeight700'>Data Source:</span> Traffik Analysis Hub</p>
              <p className='margin0'><span className='fontWeight700'>Date:</span> 2009-current</p>

              <p>
                <a
                  href="https://www.traffikanalysis.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Traffik Analysis Hub (TA Hub)
                </a>{" "}
                is a global hub for human trafficking data. TA Hub pulls data
                from multiple sources to visualize and analyze news stories
                related to trafficking. The data in Lighthouse includes news
                incidents in Texas, trafficking types, and methods. Initial data
                is based on a snapshot from September, 2021.
              </p>
            </div>
          ) : null}

          {props.charts === "Businesses of Interest" ? (
            <div>
              <p className='margin0'><span className='fontWeight700'>Data Source:</span> The Network IMB Data</p>
              <p className='margin0'><span className='fontWeight700'>Date:</span> 2016-present</p>

              <p>
                The Illicit Massage Business (IMB) data is from <a href="https://www.thenetworkteam.org/" target="_blank" rel="noopener noreferrer">The Network</a>, an intelligence-driven counter-human trafficking organization that collects and analyzes information for suspected illicit massage businesses. Data is derived from businesses listed as open and erotic on Rubmaps.ch, and includes business locations and a preliminary analysis of identified IMBs based upon sex buyer reviews. The Network does not read all reviews for all businesses to confirm for illicit content. Data is updated quarterly. In the Map, the Year filter defaults to the current year and only applies in the default Location view; the Year filter does not apply when the Location filter is set to Zip Code, since this view is based on the most recent IMB addresses.
              </p>
            </div>
          ) : null}
          {props.charts === "Hotline" ? (
            <div>
              <p className='margin0'><span className='fontWeight700'>Data Source:</span> National Hotline Cases Data</p>
              <p className='margin0'><span className='fontWeight700'>Date:</span> 2015-present</p>

              {stateLocation === 'TX' ?
                <>
                  <p>The National Hotline Data contains data regarding human trafficking situations that at least partially took place in Texas, as reported to the <a href='https://humantraffickinghotline.org/' target='_blank' rel='noopener noreferrer'>National Human Trafficking Hotline</a> and shared by <a href='https://polarisproject.org/' target='_blank' rel='noopener noreferrer'>Polaris</a>.</p>
                  <p>The data was generated based on information communicated to the National Human Trafficking Hotline via phone, email, online tip report, SMS, or Webchat. The National Human Trafficking Hotline cannot verify the accuracy of the information reported. The data is not a comprehensive report on the scale or scope of human trafficking in Texas. It should be interpreted as a limited sample of actual victim or trafficking incident data, rather than a representation of all existent victims or incidents of human trafficking. The information reported by the Trafficking Hotline is only able to represent who has access to and knowledge of the Trafficking Hotline, who has the means to reach out, and who is more likely to self-identify as a likely victim or someone in need of assistance. The data reported by Polaris should not be compared to the findings of more rigorous academic studies or prevalence estimates.</p>
                  <p>Data from Polaris, the operator of the National Human Trafficking Hotline, are based on aggregated information learned through calls, texts, online reports, webchats, and emails received by the NHTH. The data are not a comprehensive report on the scale or scope of human trafficking in any region and represent only situations which were reported to the NHTH. Trafficking assessments are conducted for each situation reported, and Polaris’s determination is based only on information provided to the Trafficking Hotline. Polaris staff do not conduct proactive investigations to corroborate or verify the claims and statements made by individuals contacting the helplines. The use of data from Polaris does not constitute endorsement of the analysis or recommendations contained here.</p>
                  <p>A "situation of trafficking" (situation) is defined as a unique situation, report, or request for resources that originated as an incoming activity (call, email, text, webchat, or online report) to the National Human Trafficking Hotline, and may include multiple activities. Each situation is a complete record of all information pertaining to a particular situation as well as a log of all actions taken by the hotline with regards to that situation. The situation serves as the central hub for all these activities. Each situation has at least one hotline call, webform, or email associated with it. Each situation also has at least one contact associated with it. All incoming activities to the hotline either generate a new situation or will be linked to an existing situation. Situation data is updated quarterly, and currency may be delayed by one or more quarters.</p>
                  <p>Note that many major cities are located in multiple counties. The Hotline data uses a single county for each of these cities, based on the Google Maps API. For example, Dallas, Texas (which is in Dallas, Collin, Denton, Kaufman, and Rockwall counties) will be recorded as only in Dallas County.</p>
                  <p>Any situation values which are less than three will be shown as zero.</p>
                </>
                :
                <>
                  <p>The National Hotline Data contains data regarding human trafficking situations that at least partially took place in Louisiana, as reported to the <a href='https://humantraffickinghotline.org/' target='_blank' rel='noopener noreferrer'>National Human Trafficking Hotline</a> and shared by <a href='https://polarisproject.org/' target='_blank' rel='noopener noreferrer'>Polaris</a>.</p>
                  <p>The data was generated based on information communicated to the National Human Trafficking Hotline via phone, email, online tip report, SMS, or Webchat. The National Human Trafficking Hotline cannot verify the accuracy of the information reported. The data is not a comprehensive report on the scale or scope of human trafficking in Louisiana. It should be interpreted as a limited sample of actual victim or trafficking incident data, rather than a representation of all existent victims or incidents of human trafficking. The information reported by the Trafficking Hotline is only able to represent who has access to and knowledge of the Trafficking Hotline, who has the means to reach out, and who is more likely to self-identify as a likely victim or someone in need of assistance. The data reported by Polaris should not be compared to the findings of more rigorous academic studies or prevalence estimates.</p>
                  <p>Data from Polaris, the operator of the National Human Trafficking Hotline, are based on aggregated information learned through calls, texts, online reports, webchats, and emails received by the NHTH. The data are not a comprehensive report on the scale or scope of human trafficking in any region and represent only situations which were reported to the NHTH. Trafficking assessments are conducted for each situation reported, and Polaris’s determination is based only on information provided to the Trafficking Hotline. Polaris staff do not conduct proactive investigations to corroborate or verify the claims and statements made by individuals contacting the helplines. The use of data from Polaris does not constitute endorsement of the analysis or recommendations contained here.</p>
                  <p>A "situation of trafficking" (situation) is defined as a unique situation, report, or request for resources that originated as an incoming activity (call, email, text, webchat, or online report) to the National Human Trafficking Hotline, and may include multiple activities. Each situation is a complete record of all information pertaining to a particular situation as well as a log of all actions taken by the hotline with regards to that situation. The situation serves as the central hub for all these activities. Each situation has at least one hotline call, webform, or email associated with it. Each situation also has at least one contact associated with it. All incoming activities to the hotline either generate a new situation or will be linked to an existing situation. Situation data is updated quarterly, and currency may be delayed by one or more quarters.</p>
                  <p>Note that many major cities are located in multiple parishes. The Hotline data uses a single parish for each of these cities, based on the Google Maps API. For example, Shreveport, Louisiana (which is in Caddo and Bossier parishes) will be recorded as only in Caddo Parish.</p>
                  <p>Any situation values which are less than three will be shown as zero.</p>
                </>
              }

            </div>
          ) : null}
          {props.charts === "Community Risk" ? (
            <div>
              <p className='margin0'><span className='fontWeight700'>Data Source:</span> County Health Rankings & Roadmaps</p>
              <p className='margin0'><span className='fontWeight700'>Date:</span> 2021</p>

              <p>
                The Community Risk model analyzes and visualizes factors that
                contribute to risk at a county level. The model uses census data
                sourced from the{" "}
                <a href="https://www.countyhealthrankings.org/sites/default/files/media/document/2021%20County%20Health%20Rankings%20Texas%20Data%20-%20v1.xlsx">
                  County Health Rankings & Roadmaps of 2021
                </a>
                . The data for the six identified trafficking risk factors
                (Disconnected Youth, High School Non-completion, Juvenile Arrest
                Rate, Severe Housing Problems, Single-parent Households, and
                Violent Crime Rates) was extracted for all 254 Texas counties
                and adapted to a model, which applied equal weightings to each
                risk factor and calculated a Risk Score. This Risk Score is
                illustrated through the density of color on the map and the
                ranking of the Counties with Prevalence of Risk chart.
              </p>
            </div>
          ) : null}
          {props.charts === "Locations" ? <div></div> : null}

          <div>
          <p>
            <em>
              The data and visualizations in Lighthouse are provided as-is with
              no warranties. The information is intended to inform Lighthouse
              users about human trafficking; it is not intended to predict human
              trafficking cases, demonstrate the efficacy of solutions, or
              identify individual victims or survivors. By using this
              information, you consent to the permitted use terms of your
              Lighthouse agreement. Allies Against Slavery is not responsible
              for your reproduction or representation of the data in Lighthouse.
            </em>
          </p>
          <p>
            <em>© 2024 ALLIES AGAINST SLAVERY. ALL RIGHTS RESERVED.</em>
            </p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDefinitionClose} color="primary" className='fontSize16 fontWeight700'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
}
