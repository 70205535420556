import React, { useEffect } from 'react';
import { useAppContext } from '../../../Lib/UserContext'
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox } from '../../../Components/Checkbox'
import Button from '@material-ui/core/Button';
import './Filter.css';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '20px',
        fontWeight: '300',
        maxHeight: '300px',
        overflowY: 'scroll'
    },
    filterWrapper: {
        display: 'flex'
    },
    active: {
        cursor: 'pointer'
    },
    inactive: {
        fontWeight: '400 !important',
        cursor: 'pointer'
    },
    checkboxLabel: {
        padding: '0px'
    },
    checkboxes: {
        marginLeft: '20px',
        display: 'flex',
        flexDirection: 'column'
    },
    saveButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '20px'
    }
}));

export default function SingleSelectionFilter(props) {
    const classes = useStyles();
    const { stateLocation } = useAppContext()
    const [filterSelection, setFilterSelection] = React.useState('');

    const handleCheckboxChange = (e, item) => {
        setFilterSelection(item);
    }

    const handleSubmit = () => {
        props.filterCallback({
            name: props.filterName,
            filter: props.page,
            selection: filterSelection,
            value: [filterSelection],
            stateLocation: stateLocation,
        })
        props.handleClose()
    }

    useEffect(() => {
        props.selectedFilters.forEach(f => {
            if (f.name === props.filterName && f.filter === props.page) {
                setFilterSelection(f.value[0])
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={`${classes.container}`}>
            <div className={classes.checkboxes}>
                {props.allOptionText &&
                    <Checkbox
                        key={props.allOptionText}
                        filterBox
                        text={props.allOptionText}
                        checked={filterSelection === props.allOptionText}
                        onClickFunc={handleCheckboxChange}
                        onClickFuncItem={props.allOptionText}
                        color='black'
                    />
                }

                {props.options?.map(option => (
                    <Checkbox
                        key={option}
                        filterBox
                        text={option}
                        checked={filterSelection === option}
                        onClickFunc={handleCheckboxChange}
                        onClickFuncItem={option}
                        color='black'
                    />
                ))}
            </div>

            <div className={classes.saveButton}>
                <Button
                    variant="contained"
                    color="primary"
                    className='btn'
                    onClick={handleSubmit}
                >
                    Save
          </Button>
            </div>
        </div>
    )
}