// import { parishData as parishJson } from '../Data/parishJson'
// import { laDistrictJson } from "../Data/laDistrictJson";
import { mapsNationalData } from '../Data/mapsNationalData'
import { parishData } from "..//Data/parishJson";
import { laRegionData } from "../Data/laRegionJson";
// import { laRegionData } from '../Data/newLARegions' // older?

export const mapsLAData = {
  mapZoom: 5,
  reportMapZoom: 4,
  mapBoxCenter: [-92.329102, 30.39183],
  mapBoxCenterReport: [-92.329102, 30.39183],
  mapMaxBounds: [[-97.77287069367998, 25.7091], [-87.26493500455376, 35.743288738760796]],
  mapMaxBoundsReport: [[-97.77287069367998, 25.7091], [-87.26493500455376, 35.743288738760796]],
  reportMapWidth: '577px',

  'Services': {
    // from map.js, waiting for client to lmk which orgList is correct for LA
  //   [
  //     "Behavioral Health",
  //     "CAC",
  //     "DCFS",
  //     "District Attorney's Office",
  //     "Drop-In Center",
  //     "DV/SA Services",
  //     "Emergency Shelter",
  //     "FINS",
  //     "Homelessness Program",
  //     "HT Program, Non-Residential",
  //     "HT Program, Residential",
  //     "Immigrant Services",
  //     "Juvenile Justice",
  //     "Law Enforcement",
  //     "Legal Assistance",
  //     "Long Term Residential",
  //     "Medical",
  //     "School",
  //     "Taskforce",
  //     "Transitional Housing",
  //     "Other",
  //     "National Referral Directory",
  // ]
      
    orgList: [
      { name: "Behavioral Health", color: 0},
      { name: "CAC", color: 1},
      { name: "DCFS", color: 2},
      { name: "District Attorney's Office", color: 3},
      { name: "Drop-In Center", color: 4},
      { name: "DV/SA Services", color: 5},
      { name: "Emergency Shelter", color: 6},
      { name: "FINS", color: 7},
      { name: "Homelessness Program", color: 8},
      { name: "HT Program, Non-Residential", color: 9},
      { name: "HT Program, Residential", color: 10},
      { name: "Immigrant Services", color: 11},
      { name: "Juvenile Justice", color: 12},
      { name: "Law Enforcement", color: 13},
      { name: "Legal Assistance", color: 14},
      { name: "Long Term Residential", color: 15},
      { name: "Medical", color: 16},
      { name: "School", color: 17},
      { name: "Taskforce", color: 18},
      { name: "Therapy/Counseling", color: 19},
      { name: "Transitional Housing", color: 20},
      { name: "Victim Advocacy", color: 21},
      { name: "Other", color: 22},
      { name: "National Referral Directory", color: 23},
    ],

    'Provider Location ': { // info based on filter selection
      'State': {
        mapJson: null,
        dataVar: 'orgList',
        locSourceVar: 'county',
        popUpLocName: 'Parish',
        popUpOrgTypeName: 'Organization Type',
        featureLocProp: '', // ???
      },
      'Parish': {
        mapJson: parishData,
        dataVar: 'orgList',
        locSourceVar: 'county',
        popUpLocName: 'Parish',
        popUpOrgTypeName: 'Organization Type',
        featureLocProp: 'COUNTY', // ???
      },
      'Region': {
        mapJson: laRegionData,
        dataVar: 'orgList',
        locSourceVar: 'region',
        popUpLocName: 'Parish',
        popUpOrgTypeName: 'Organization Type',
        featureLocProp: 'region', // ???
      },
    },
  }, // Services

  'Federal Cases': {
    mapZoom: mapsNationalData?.mapZoom,
    reportMapZoom: mapsNationalData?.reportMapZoom,
    mapBoxCenter: mapsNationalData?.mapBoxCenter,
    mapBoxCenterReport: mapsNationalData?.mapBoxCenterReport,
    mapMaxBounds: mapsNationalData?.mapMaxBounds,
    mapMaxBoundsReport: mapsNationalData?.mapMaxBoundsReport,
    reportMapWidth: mapsNationalData?.reportMapWidth,
    'Map View': mapsNationalData['Federal Cases']['Map View'],
    mapInfo: mapsNationalData['Federal Cases'].mapInfo,
  },
  'State Policy': {
    mapZoom: mapsNationalData?.mapZoom,
    reportMapZoom: mapsNationalData?.reportMapZoom,
    mapBoxCenter: mapsNationalData?.mapBoxCenter,
    mapBoxCenterReport: mapsNationalData?.mapBoxCenterReport,
    mapMaxBounds: mapsNationalData?.mapMaxBounds,
    mapMaxBoundsReport: mapsNationalData?.mapMaxBoundsReport,
    reportMapWidth: mapsNationalData?.reportMapWidth,
    'Data': mapsNationalData['State Policy']['Data'],
    'CriminalData': mapsNationalData['State Policy']['CriminalData'],
    mapInfo: mapsNationalData['State Policy'].mapInfo
  }
}