import { availableDashboards } from "../../Data/availableDashboards.js";
import { mapNameToAPIMethod, chartNameToAPIMethod } from "../Constants.js";

export function getMapOrChartData(stateLocation) {
    const dashboardData = availableDashboards.filter((d) => d.stateLocation === stateLocation)[0]?.dashboardDataFile;
    if (!dashboardData) {
      return {};
    }

    const defaultFilterArray = [
        {
          name: "Time",
          filter: "Services",
          selection: "Quarter & Fiscal Year",
          value: ["All quarters", "All years"],
          stateLocation: "TX",
        },
        {
          name: "Time ",
          filter: "Services",
          selection: "Fiscal Year",
          value: ["All years"],
          stateLocation: "TX",
        },
        {
          name: "Location ",
          filter: "Services",
          selection: "State",
          value: ["State"],
          stateLocation: "TX",
        },
        {
          name: "Location",
          filter: "Services",
          selection: "County",
          value: ["All counties"],
          stateLocation: "TX",
        },
        {
          name: "Provider Location ",
          filter: "Services",
          selection: "State",
          value: ["State"],
          stateLocation: stateLocation,
        },
        {
          name: "Location of Origin",
          filter: "Services",
          selection: "Parish",
          value: ["All parishes"],
          stateLocation: "LA",
        },
      {
        name: 'Provider Location',
        filter: 'Services',
        selection: stateLocation === 'LA' ? 'Parish' : 'County',
        value: stateLocation === 'LA' ? ['All parishes'] : ['All counties'],
        stateLocation: stateLocation,
      },
        {
          name: "Ages",
          filter: "Services",
          value: ["All ages"],
          stateLocation: "LA",
        },
        {
          name: "Organizations",
          filter: "Services",
          value: ["All organizations"],
          stateLocation: stateLocation,
        },
        {
          name: "Trafficking Types",
          filter: "Services",
          value: ["All types"],
          stateLocation: "LA",
        },
        {
          name: "Time",
          filter: "Services",
          value: ["All years"],
          stateLocation: "LA",
        },
        {
          name: "Victim Status",
          filter: "Services",
          value: ["Confirmed", "Suspected"],
          stateLocation: "LA",
      },
        {
          name: "Time",
          filter: "Locations",
          value: ["All years"],
        },
        {
          name: "Location Type",
          filter: "Locations",
          value: ["Parish of Trafficking"],
        },
        {
          name: "Location",
          filter: "Locations",
          selection: "Parish",
          value: ["All parishes"],
        },
        {
          name: "Ages",
          filter: "Locations",
          value: ["All ages"],
        },
        {
          name: "Organizations",
          filter: "Locations",
          value: ["All organizations"],
        },
        {
          name: "Trafficking Types",
          filter: "Locations",
          value: ["All types"],
        },
        {
          name: "Victim Status",
          filter: "Locations",
          value: ["Confirmed", "Suspected"],
          stateLocation: "LA",
        },
        {
          name: "Time",
          filter: "Care Coordination",
          selection: "Quarter & Fiscal Year",
          value: ["All quarters", "All years"],
        },
        {
          name: "Location",
          filter: "Care Coordination",
          stateLocation: stateLocation,
          selection: stateLocation === "TX" ? "County" : "Region",
          value: stateLocation === "TX" ? ["All counties"] : ['All regions'],
        },
        {
          name: "Trafficking Types",
          filter: "Care Coordination",
          stateLocation: stateLocation,
          value: ["All types"],
        },
        {
          name: "Individuals Served",
          filter: "Care Coordination",
          stateLocation: stateLocation,
          value: ["Trafficking Victims"],
        },
        {
          name: "Time",
          filter: "Screenings",
          selection: "All time",
          value: ["All time"],
        },
        {
          name: "Location",
          filter: "Screenings",
          selection: "County",
          value: stateLocation === "TX" ? ["All counties"] : ["All parishes"],
        },
        {
          name: "Organizations",
          filter: "Screenings",
          value: ["All organizations"],
        },
        {
          name: "Screenings",
          filter: "Screenings",
          value: ["All screenings"],
        },
        {
          name: "Ages",
          filter: "Screenings",
          value: ["All ages"],
        },
        {
          name: "Indicators",
          filter: "Screenings",
          value: ["Clear Concern"],
        },
        {
          name: "Agency Type",
          filter: "Department of Public Safety",
          value: ["Arresting Agency (ORIA)"],
        },
        {
          name: "Offenses",
          filter: "Department of Public Safety",
          value: ["All offenses"],
        },

        {
          name: "Location",
          filter: "Department of Public Safety",
          selection: "County",
          value: ["All counties"],
        },
        {
          name: 'Map View',
          filter: 'Federal Cases',
          stateLocation: stateLocation,
          value: ['State Prosecuted'],
          stateClickedData: null
      },
      {
        name: 'Year Case Filed',
        filter: 'Federal Cases',
        value: ["All Years"]
      },
      {
        name: 'Location ',
        filter: 'Federal Cases',
        stateLocation: stateLocation,
        value: ['All States'],
      },
        {
          name: 'Location',
          filter: 'Federal Cases',
          stateLocation: stateLocation,
          selection: dashboardData.Cases.Federal.defaultChartLoSelection,
          value: dashboardData.Cases.Federal.defaultChartLoValue,
          stateValue: dashboardData.Cases.Federal?.defaultStateValue,
          districtValue: dashboardData.Cases.Federal?.defaultDistrictValue,
          countyValue: dashboardData.Cases.Federal?.defaultCountyValue
        },
        {
          name: "Trafficking Type",
          filter: "Federal Cases",
          value: ["All types"],
        },
        {
          name: "Location",
          filter: "Texas Department of Criminal Justice",
          selection: "County",
          value: ["All counties"],
        },
        {
          name: "Offenses",
          filter: "Texas Department of Criminal Justice",
          value: ["All offenses"],
        },
        {
          name: "Ad Sites",
          filter: "Demand",
          value: ["All Sites"],
        },
        {
          name: "Location",
          filter: "Businesses of Interest",
          selection: "County",
          stateLocation: stateLocation === "TX" ? "TX" : "LA",
          value: stateLocation === "TX" ? ["All counties"] : ["All parishes"],
        },
        {
          name: "IMB Analysis",
          stateLocation: stateLocation === "TX" ? "TX" : "LA",
          filter: "Businesses of Interest",
          value: ["All Businesses"],
        },
        {
          name: "Year",
          filter: "Businesses of Interest",
          value: ["2024"],
        },
        {
          name: "Location ",
          filter: "Businesses of Interest",
          value: stateLocation === "TX" ? ["County"] : ["Parish"],
        },
        {
          name: "Location",
          filter: "Hotline",
          selection: "County",
          value: stateLocation === "TX" ? ["All counties"] : ["All parishes"], // backend has lowercase 'counties'
        },
        {
          name: "Cases",
          filter: "Hotline",
          selection: "All Cases",
          value: ["Involves Minors", "Involves Adults"],
        },
        {
          name: "Time",
          filter: "Hotline",
          selection: "Quarter & Fiscal Year", // Fiscal on the backend, Calendar year on the frontend
          value: ["All years"],
        },
        {
          name: 'Year',
          filter: 'State Policy',
          value: ['2023']
        },
        {
          name: 'Policy',
          filter: 'State Policy',
          value: ['All policies']
        },
        {
          name: 'Map View',
          filter: 'State Policy',
          value: ['All Other Trafficking Policy']
        },
        {
          name: 'Sentencing Requirement',
          filter: 'State Policy',
          value: ['Minor Sex Trafficking Maximum']
        },
        {
          name: 'Location',
          filter: 'State Policy',
          value: ["All States"]
        },
    ];

    return {
        defaultFilterArray,
        dashboardData
    };
};

export function getAPIMethodByMapChartName(mapChartName, isMap, stateLocation) {
  if (isMap) {
    return mapNameToAPIMethod[mapChartName];
  }

  return stateLocation === "LA"
    ? chartNameToAPIMethod[mapChartName]["LA"]
    : chartNameToAPIMethod[mapChartName].general;
}