import React from "react";
import { useAppContext } from "../../../../Lib/UserContext";
//import BringingJusticeModal from "./BringingJusticeMeasures";
import AdvocacyModal from "./AdvocacyMeasures";
import DropinModal from "./DropinMeasures";
import EmergencyModal from "./EmergencyMeasures";
//import InnovativeServicesModal from "./InnovativeServices";
import LongTermModal from "./LongtermMeasures";
//import PreventionModal from "./PreventionMeasures";
//import TherapyCounselingModal from "./TherapyCounselingMeasures";
//import OtherModal from "./OtherMeasures";
import NationalReferralModal from "./NationalReferralDirectory";
//import OVCModal from "./OVCMeasures";
import LouisianaModal from './Louisiana/LouisianaMeasures';
import FLServices from './FLServices'

export default function Services(props) {
  const { stateLocation } = useAppContext();

  // const addToReportPage = (data) => {
  //   props.addToReportPage(data, "Services");
  // };

  return (
    <div>
      {stateLocation === 'TX' &&
        <div>
          {/* <BringingJusticeModal data={props.data} addToReports={addToReportPage} id={props.chart}/>  */}
          <div className="services-border"></div>
          <AdvocacyModal data={props.data} addToReports={props.addToReportPage} id={props.chart} />
          <div className="services-border"></div>
          <DropinModal data={props.data} addToReports={props.addToReportPage} id={props.chart} />
          <div className="services-border"></div>
          <EmergencyModal data={props.data} addToReports={props.addToReportPage} id={props.chart} />
          <div className="services-border"></div>
          {/* <InnovativeServicesModal data={props.data} addToReports={addToReportPage} id={props.chart}/> */}
          <div className="services-border"></div>
          <LongTermModal data={props.data} addToReports={props.addToReportPage} id={props.chart} />
          <div className="services-border"></div>
          {/* <PreventionModal data={props.data}addToReports={addToReportPage} id={props.chart}/> */}
          {/* <div className="services-border"></div> */}
          {/* <TherapyCounselingModal data={props.data} addToReports={addToReportPage} id={props.chart}/> */}
          {/* <div className="services-border"></div> */}
          {/* <OtherModal data={props.data} addToReports={addToReportPage} id={props.chart}/> */}
          <div className="services-border"></div>
          <NationalReferralModal data={props.data} addToReports={props.addToReportPage} id={props.chart} />
          {/* <div className="services-border"></div> */}
          {/* <OVCModal data={props.data} addToReports={addToReportPage} id={props.chart}/> */}
        </div>
      }

      {stateLocation === 'LA' &&
        <LouisianaModal data={props.data} addToReports={props.addToReportPage} id={props.chart} />
      }

      {stateLocation === 'FL' &&
        <FLServices data={props.data} addToReports={props.addToReportPage} id={props.chart} />
      }
    </div>
  );
}
