import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import './Filter.css';
import { withStyles } from '@material-ui/core/styles';

const BlackCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '20px',
    fontWeight: '300',
    height: '400px',
    overflowY: 'scroll',
    //width: '500px'
  },
  filterWrapper: {
    display: 'flex'
  },
  active: {
    cursor: 'pointer'
  },
  inactive: {
    fontWeight: '400 !important',
    cursor: 'pointer'
  },
  checkboxLabel: {
    padding: '0px'
  },
  checkboxes: {
    marginLeft: '20px',
    display: 'flex',
    flexDirection: 'column'
  },
  saveButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '20px'
  }
}));

export default function MultipleSelectionFilter(props) {
  const classes = useStyles();
  const [filterSelections, setFilterSelections] = React.useState([]);

  const handleCheckboxChange = (e, item) => {
    if (filterSelections.includes(item)) {
      let newArray = filterSelections.filter(x => x !== item);
      setFilterSelections(newArray);
    } else {
      let newArray = [...filterSelections]
      let filteredArray = newArray.filter(x => x !== props.allOptionText);
      filteredArray.push(item);
      setFilterSelections(filteredArray);
    }
  }


  const handleAllCheckboxChange = (e) => {
    if (e.target.checked) {
      setFilterSelections([props.allOptionText]);
    } else {
      setFilterSelections([]);
    }
  }

  const handleSubmit = () => {
    props.filterCallback({
      name: props.filterName,
      filter: props.page,
      selection: filterSelections,
      value: filterSelections,
      stateLocation: props.stateLocation
    })
    props.handleClose()
  }

  useEffect(() => {
    props.selectedFilters.forEach(s => {
      if (s.name === props.filterName && s.filter === props.page) {
        setFilterSelections(s.value)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classes.container}>
      <div className={classes.checkboxes}>
        <FormControlLabel className="checkboxLabel"
          control={
            <BlackCheckbox
              checked={filterSelections.includes(props.allOptionText)}
              onChange={e => handleAllCheckboxChange(e, props.allOptionText)} name="checkedA" />
          }
          label={props.allOptionText}
        />

        {props.options?.map((org, key) => {
          return (
            <div key={key} style={{ display: 'flex', flexDirection: 'column' }}>
              <FormControlLabel key={key} className="checkboxLabel"
                control={
                  <BlackCheckbox
                    checked={filterSelections.includes(org)}
                    onChange={e => handleCheckboxChange(e, org)} name="checkedA" />
                }
                label={org}
              />
            </div>
          )
        })}

      </div>

      <div className={classes.saveButton}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Save
          </Button>
      </div>
    </div>
  )
}