import React from 'react'

//****/ inline styles for the report pdf!

export const Checkbox = (props) => {
    const onClick = (e) => {
        let item = props?.onClickFuncItem ?? e
        if (props?.onClickFunc && !props.report) {
            props.onClickFunc(e, item)
        } else {
            console.log('')
        }
    }
    return (
        <div
            className='field'
            style={{
                display: 'flex',
                alignItems: 'center',
                margin: props.maplegend ? (props.report ? (props.noTitle ? '0px 0px 4px 0px' : '0px 0px 4px 4px') : '0px 0px 4px 10px') : '0px 20px 14px 0px',
            }}
        >
            <label
                htmlFor={props.text}
                className='chartCheckboxContainer'
                style={{
                    cursor: props.report || props.cursorDefault ? 'default' : 'pointer',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    position: 'relative',
                    fontSize: props.filterBox ? '16px' : '12px',
                    fontWeight: '600',
                    padding: props.report ? (props.maplegend ? '0px 0px 0px 0px' : '0px' ) : '0px',
                    margin: props.report ? (props.maplegend ? '0px 0px 0px 0px' : '0px 0px 0px 0px') : '0px',
                }}
            >
                <input
                    type='checkbox'
                    className='chartCheckboxes'
                    checked={props.report ? false : props.checked}
                    onChange={(e) => console.log('')}
                    style={{
                        position: 'absolute',
                        opacity: '0',
                        cursor: props.report ? 'default' : 'pointer',
                        height: '0',
                        width: '0',
                        backgroundColor: 'transparent'
                    }}
                />
                    <span
                    className={`chartCheckmark ${props.hideCheckmarkWhenChecked ? 'hideCheckMark' : ''}`}
                        onClick={(e) => onClick(e)}
                        style={{
                            backgroundColor: props.checked || props.report || props.colorWhenFalse ? props.color : 'transparent',
                            borderColor: props.checked || props.report || props.colorWhenFalse ? props.color : '#0000008a',
                            position: 'relative',
                            display: 'block',
                            marginRight: '8px',
                            height: '17px',
                            width: '17px',
                            borderRadius: '4px',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                        }}
                    />

                {props.boldText ? <strong>{props.text}</strong> : props.text}
            </label>
        </div >
    )
}