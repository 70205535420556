/* eslint-disable array-callback-return */
import { countyData } from "../../../Data/countyJson";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import { regionData } from "../../../Data/regionJsonV2";

export function ServicesTXLayer(map, location, data, legend, report) {
  var locationSelection;
  for (let i = 0; i < location.length; i++) {
    if (location[i].name === 'Location ' && location[i].filter === 'Services' && location[i].stateLocation === 'TX') {
      locationSelection = location[i].value[0];
    }
  }

  let sourceMapInfo = {}

  if (locationSelection === 'State') {
    sourceMapInfo = {}
  } else if (locationSelection === 'Region') {
    sourceMapInfo = {
      sourceData: regionData,
      layer1IdSource: 'region',
      layer2Id: 'region-fills',
      mapFeatureProp: 'region'
    }
  } else { // County
    sourceMapInfo = {
      sourceData: countyData,
      layer1IdSource: 'county',
      layer2Id: 'county-fills',
      mapFeatureProp: 'COUNTY'
    }
  }

  const loadPopups = () => {
    legend.map(legendObj => {
      data.orgList?.map(item => {
        if (legendObj.name === item.org_type && legendObj.checked) {
          const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
            `<p>${item.organization}</p>
          <p>County: <span style="font-weight: 300 !important">${item.county} </span></p>
          <p>Organization Type: <span style="font-weight: 300 !important">${item.org_type} </span> </p>`
          );
          return new mapboxgl.Marker({ color: legendObj.color, scale: report ? 0.2 : 0.5 })
            .setLngLat([item.long, item.lat])
            .setPopup(popup)
            .addTo(map);
        }
      })
    })
  }

    var hoveredStateId = null;

  map.on("load", function () {
    if (locationSelection !== 'State') {
      map.addSource(sourceMapInfo.layer1IdSource, {
        type: "geojson",
        data: sourceMapInfo.sourceData,
      });
      map.addLayer({
        id: sourceMapInfo.layer1IdSource,
        type: "line",
        source: sourceMapInfo.layer1IdSource,
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "#28ADE3",
          "line-width": 1,
        },
      });

      map.addLayer({
        id: sourceMapInfo.layer2Id,
        type: "fill",
        source: sourceMapInfo.layer1IdSource,
        layout: {},
        paint: {
          "fill-color": "#28ADE3",
          "fill-opacity": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            0.5,
            0,
          ],
        },
      });
    }

      loadPopups()

      var popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      });

      map.on("mousemove", sourceMapInfo.layer2Id, function (e) {
        if (hoveredStateId !== e.features[0].id) {
          if (e.features.length > 0) {
            if (hoveredStateId) {
              map.setFeatureState(
                { source: sourceMapInfo.layer1IdSource, id: hoveredStateId },
                { hover: false }
              );
            }
            hoveredStateId = e.features[0].id;
            map.setFeatureState(
              { source: sourceMapInfo.layer1IdSource, id: hoveredStateId },
              { hover: true }
            );
          }

          // var coordinates = e.features[0].geometry.coordinates[0][0];
          // if (coordinates.length > 2) {
          //   for (var i = 2; i < coordinates.length; i--) {
          //     coordinates.pop();
          //   }
          // }
          var description = e.features[0].properties[sourceMapInfo.mapFeatureProp];
          popup.setLngLat(e.lngLat).setHTML(description).addTo(map);
        }
      });

      map.on("mouseleave", sourceMapInfo.layer2Id, function () {
        if (hoveredStateId) {
          map.setFeatureState(
            { source: sourceMapInfo.layer1IdSource, id: hoveredStateId },
            { hover: false }
          );
        }
        hoveredStateId = null;
        popup.remove();
      });
    });
}