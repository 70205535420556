export let flCircuitRegionList = [
    'All regions',
    'First Circuit',
    'Second Circuit',
    'Third Circuit',
    'Fourth Circuit',
    'Fifth Circuit',
    'Sixth Circuit',
    'Seventh Circuit',
    'Eighth Circuit',
    'Ninth Circuit',
    'Tenth Circuit',
    'Eleventh Circuit',
    'Twelfth Circuit',
    'Thirteenth Circuit',
    'Fourteenth Circuit',
    'Fifteenth Circuit',
    'Sixteenth Circuit',
    'Seventeenth Circuit',
    'Eighteenth Circuit',
    'Nineteenth Circuit',
    'Twentieth Circuit',
]