import React, { Fragment, useEffect, useState } from "react";
import Markdown from "react-markdown"

import useContentful from "../../Lib/Hooks/useContentful";

import Layout from "./Layout";
import LoginCTABlock from "../../Components/PublicSite/CTABlock/LoginCTABlock";
import MediaWrapper from "../../Components/PublicSite/MediaWrapper/MediaWrapper";
import Hero from "../../Components/PublicSite/Hero/Hero";
import MapWrapper from "../../Components/PublicSite/MapWrapper/MapWrapper";
import { ENUMS } from "../../Lib/Enums";

export default function GeographicTrends() {
  const { getSiteContent, getMapsContent, getModalContent } = useContentful();
  const [siteContent, setSiteContent] = useState({});
  const [listOfMaps, setListOfMaps] = useState([]);
  const [listOfModals, setListOfModals] = useState([]);

  const getSiteHeaderTitle = (header) => {
    if (!header) {
      return (<Fragment></Fragment>)
    }

    const headerElementStr = header
      .replace("\\", "<span className='site-header-title title highlight'>")
      .replace("\\", "</span>");
    return (
      <div
        className="site-header-title title"
        dangerouslySetInnerHTML={{ __html: headerElementStr }}
      >
      </div>
    );
  };

  useEffect(() => {
    (async () => {
      const response = await getSiteContent(ENUMS.ROUTES.GEOGRAPHIC_TRENDS.CID);
      setSiteContent(response);
    })();
  }, [getSiteContent]);

  useEffect(() => {
    (async () => {
      const response = await getModalContent(ENUMS.ROUTES.GEOGRAPHIC_TRENDS.CID);
      setListOfModals(response);
    })();
  }, [getModalContent]);

  useEffect(() => {
    (async () => {
      const response = await getMapsContent(ENUMS.ROUTES.GEOGRAPHIC_TRENDS.CID);
      response.reverse();
      setListOfMaps(response);
    })();
  }, [getMapsContent]);

  return (
    <Layout>
      <div className="geographic-trends">
        <Hero
          title={getSiteHeaderTitle(siteContent.header)}
          body={siteContent.headerBody}
          button={siteContent.headerButton}
          buttonLink={siteContent.headerButtonLink}
        />
        <MediaWrapper
          url={siteContent.headerMedia?.url}
          contentType={siteContent.headerMedia?.contentType}
          title={siteContent.headerMedia?.title}
        />
        <div className="site-body">
          <div className="site-section">
            <div className="site-header">
              {getSiteHeaderTitle(siteContent.introTitle)}
            </div>
            <div className="site-section-body">
              <div className="site-section-body-group column-group">
                {
                  siteContent.introBodyColumns?.split("\n")?.map((column, key) => {
                    return column.trim()
                      ? (
                        <div key={key} className="column-group-item">
                          <Markdown>
                            {column}
                          </Markdown>
                        </div>
                      )
                      : <Fragment key={key}></Fragment>
                  })
                }
              </div>
              <div className="site-section-body-group">
                <Markdown>
                  {siteContent.introBodySingle}
                </Markdown>
              </div>
            </div>
          </div>
          <div className="site-section-wrapper">
            {
              listOfMaps?.map((mapItem, key) => (
                <div className="site-section">
                  <div className="site-header">
                    {getSiteHeaderTitle(mapItem.mapHook)}
                  </div>
                  <div className="site-section-body">
                    <div>
                      <Markdown>
                        {mapItem.mapBi}
                      </Markdown>
                    </div>
                  </div>
                  <MapWrapper
                    key={key}
                    mapName={mapItem.mapName}
                    stateLocation={mapItem.mapStateLocation}
                    mapTitle={mapItem.mapTitle}
                    mapInsight={mapItem.mapInsight}
                    mapSource={mapItem.mapSource}
                    mapPageAssociated={mapItem.mapPageAssociated}
                    citationGuidelinesCta={mapItem.citationGuidelinesCta}
                    disclaimerCta={mapItem.disclaimerCta}
                    modals={listOfModals}
                  />
                </div>
              ))
            }
          </div>
          <div className="site-section">
            <LoginCTABlock
              ctaTitle={siteContent.ctaTitle}
              ctaBody={siteContent.ctaBody}
              ctaButton={siteContent.ctaButton}
              ctaButtonLink={siteContent.ctaButtonLink}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}
