import React, { useEffect, useState, Fragment } from "react";

import Layout from "./Layout";
import Hero from "../../Components/PublicSite/Hero/Hero";
import MediaWrapper from "../../Components/PublicSite/MediaWrapper/MediaWrapper";
import LogoSection from "../../Components/PublicSite/LogoSection/LogoSection";
import LoginCTABlock from "../../Components/PublicSite/CTABlock/LoginCTABlock";

import useContentful from "../../Lib/Hooks/useContentful";
import { ENUMS } from "../../Lib/Enums";

export default function Partnerships() {
  const { getSiteContent, getLogoContent } = useContentful();
  const [siteContent, setSiteContent] = useState({});
  const [logoItems, setLogoItems] = useState([]);

  const getSiteHeaderTitle = (header) => {
    if (!header) {
      return (<Fragment></Fragment>)
    }

    const headerElementStr = header
      .replace("\\", "<span className='site-header-title title highlight'>")
      .replace("\\", "</span>");
    return (
      <div
        className="site-header-title title"
        dangerouslySetInnerHTML={{ __html: headerElementStr }}
      >
      </div>
    );
  };

  useEffect(() => {
    (async () => {
      const pageCid = ENUMS.ROUTES.PARTNERSHIPS.CID;
      const siteContentRes = await getSiteContent(pageCid);
      const logoItemsRes = await getLogoContent();

      setSiteContent(siteContentRes);
      setLogoItems(logoItemsRes);
    })();
  }, [getSiteContent, getLogoContent]);

  return (
    <Layout>
      <div className="partnerships">
        <Hero
          title={getSiteHeaderTitle(siteContent.header)}
          body={siteContent.headerBody}
          button={siteContent.headerButton}
          buttonLink={siteContent.headerButtonLink}
        />
        <MediaWrapper
          url={siteContent.headerMedia?.url}
          contentType={siteContent.headerMedia?.contentType}
          title={siteContent.headerMedia?.title}
        />
        <div className="site-body">
          <div className="site-section">
            <div className="site-header">
              {getSiteHeaderTitle(siteContent.introTitle)}
            </div>
            {
              siteContent.introBodyColumns
              ?  (
                <div className="site-section-body-group column-group">
                  {
                      siteContent.introBodyColumns.split("\n")?.map((column, key) => {
                        return column.trim()
                          ? (
                            <div key={key} className="column-group-item">
                              {column}
                            </div>
                          )
                          : <Fragment key={key}></Fragment>
                      })
                  }
                </div>
              )
              : <Fragment></Fragment>
            }
            <LogoSection
              title={siteContent.section1Title}
              description={siteContent.section1Body}
              logoItems={logoItems.filter((item) => item.sectionNumber === 1)}
            />
            <LogoSection
              title={siteContent.section2Title}
              description={siteContent.section2Body}
              logoItems={logoItems.filter((item) => item.sectionNumber === 2)}
            />
            <LogoSection
              title={siteContent.section3Title}
              description={siteContent.section3Body}
              logoItems={logoItems.filter((item) => item.sectionNumber === 3)}
            />
          </div>
          <div className="site-section">
            <LoginCTABlock
              ctaTitle={siteContent.ctaTitle}
              ctaBody={siteContent.ctaBody}
              ctaButton={siteContent.ctaButton}
              ctaButtonLink={siteContent.ctaButtonLink}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}
