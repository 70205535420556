export let flRegionCircuitJson = {
    "type": "FeatureCollection",
    "features": [
        {
            "id": 100,
            "type": "Feature",
            "properties": {
                "GEO_ID": "0500000US12071",
                "STATE": 12,
                "COUNTY": "Lee",
                "POP": 0,
                "YOUTHPOP": 0,
                "SCREENINGS": 0,
                "CLEARCONCERN": 0,
                "POSSIBLECONCERN": 0,
                "NOCONCERN": 0,
                "SITUATION_INVOVLES_MINORS": "true",
                "INVOLVESMINORS": 0,
                "CASE_INVOLVES_ADULTS": "true",
                "INVOLVESADULTS": 0,
                "TOTAL": 0,
                "CENSUSAREA": 784.513,
                "CIRCUIT": "Twentieth Circuit"
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            [
                                -82.255777,
                                26.703437
                            ],
                            [
                                -82.268007,
                                26.682791
                            ],
                            [
                                -82.238872,
                                26.636433
                            ],
                            [
                                -82.222131,
                                26.590402
                            ],
                            [
                                -82.201402,
                                26.55631
                            ],
                            [
                                -82.186441,
                                26.489221
                            ],
                            [
                                -82.172917,
                                26.467658
                            ],
                            [
                                -82.126671,
                                26.436279
                            ],
                            [
                                -82.098115,
                                26.424959
                            ],
                            [
                                -82.063114,
                                26.425459
                            ],
                            [
                                -82.013913,
                                26.452058
                            ],
                            [
                                -82.062551,
                                26.470131
                            ],
                            [
                                -82.088423,
                                26.455182
                            ],
                            [
                                -82.120046,
                                26.473581
                            ],
                            [
                                -82.149368,
                                26.477605
                            ],
                            [
                                -82.177541,
                                26.502328
                            ],
                            [
                                -82.214337,
                                26.602944
                            ],
                            [
                                -82.218342,
                                26.626407
                            ],
                            [
                                -82.255777,
                                26.703437
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -81.845834,
                                26.330378
                            ],
                            [
                                -81.80622900776615,
                                26.08344153397466
                            ],
                            [
                                -81.74715854012646,
                                25.934743806000505
                            ],
                            [
                                -81.68859175458584,
                                25.841300397775886
                            ],
                            [
                                -81.6057477354655,
                                25.864906255489828
                            ],
                            [
                                -81.3618490512265,
                                25.7727703097955
                            ],
                            [
                                -81.34040070524112,
                                25.786677140200272
                            ],
                            [
                                -81.34452155837602,
                                25.803749620953827
                            ],
                            [
                                -80.87308059636734,
                                25.805429842672968
                            ],
                            [
                                -80.88564343411954,
                                26.958784876371126
                            ],
                            [
                                -80.87081995289195,
                                27.155737530028286
                            ],
                            [
                                -80.94403444337287,
                                27.21069093063644
                            ],
                            [
                                -81.1686231023901,
                                27.2095873165724
                            ],
                            [
                                -81.16792393931634,
                                27.122918226272006
                            ],
                            [
                                -81.26737510973255,
                                27.120959495264906
                            ],
                            [
                                -81.26842869212517,
                                27.032692807263402
                            ],
                            [
                                -81.38900538247752,
                                27.03439100743322
                            ],
                            [
                                -82.25479139343375,
                                27.03288240873461
                            ],
                            [
                                -82.25498407274105,
                                26.945016297770135
                            ],
                            [
                                -82.37553239277099,
                                26.946819915301603
                            ],
                            [
                                -82.29122739639568,
                                26.826301074101227
                            ],
                            [
                                -82.26744090746003,
                                26.717403425512273
                            ],
                            [
                                -82.181565,
                                26.681712
                            ],
                            [
                                -82.137869,
                                26.637441
                            ],
                            [
                                -82.111996,
                                26.54085
                            ],
                            [
                                -82.105672,
                                26.48393
                            ],
                            [
                                -82.07175,
                                26.492554
                            ],
                            [
                                -82.06715,
                                26.513252
                            ],
                            [
                                -82.043577,
                                26.519577
                            ],
                            [
                                -82.00908,
                                26.505203
                            ],
                            [
                                -82.01368,
                                26.490829
                            ],
                            [
                                -81.969509,
                                26.476505
                            ],
                            [
                                -81.964212,
                                26.457957
                            ],
                            [
                                -81.91171,
                                26.427158
                            ],
                            [
                                -81.868983,
                                26.378648
                            ],
                            [
                                -81.845834,
                                26.330378
                            ]
                        ]
                    ]
                ],
                "type": "MultiPolygon"
            },
        },
        {
            "id": 1,
            "type": "Feature",
            "properties": {
                "GEO_ID": "0500000US12093",
                "STATE": 12,
                "COUNTY": "Okeechobee",
                "POP": 0,
                "YOUTHPOP": 0,
                "SCREENINGS": 0,
                "CLEARCONCERN": 0,
                "POSSIBLECONCERN": 0,
                "NOCONCERN": 0,
                "SITUATION_INVOVLES_MINORS": "true",
                "INVOLVESMINORS": 0,
                "CASE_INVOLVES_ADULTS": "true",
                "INVOLVESADULTS": 0,
                "TOTAL": 0,
                "CENSUSAREA": 768.908,
                "CIRCUIT": "Nineteenth Circuit"
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -80.44701617486577,
                            27.86071598746838
                        ],
                        [
                            -80.5089175378616,
                            27.82214861544453
                        ],
                        [
                            -80.86877675758757,
                            27.822625191590383
                        ],
                        [
                            -80.87315,
                            27.642288
                        ],
                        [
                            -81.142164,
                            27.643238
                        ],
                        [
                            -81.213717,
                            27.528654
                        ],
                        [
                            -80.970916,
                            27.217698
                        ],
                        [
                            -80.943186,
                            27.210599
                        ],
                        [
                            -80.871169,
                            27.154951
                        ],
                        [
                            -80.885639,
                            26.958919
                        ],
                        [
                            -80.24739728205384,
                            26.95708463092221
                        ],
                        [
                            -80.07958255950169,
                            26.97066544546323
                        ],
                        [
                            -80.38496913983194,
                            27.74891707747207
                        ],
                        [
                            -80.44701617486577,
                            27.86071598746838
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "id": 2,
            "type": "Feature",
            "properties": {
                "GEO_ID": "0500000US12081",
                "STATE": 12,
                "COUNTY": "Manatee",
                "POP": 0,
                "YOUTHPOP": 0,
                "SCREENINGS": 0,
                "CLEARCONCERN": 0,
                "POSSIBLECONCERN": 0,
                "NOCONCERN": 0,
                "SITUATION_INVOVLES_MINORS": "true",
                "INVOLVESMINORS": 0,
                "CASE_INVOLVES_ADULTS": "true",
                "INVOLVESADULTS": 0,
                "TOTAL": 0,
                "CENSUSAREA": 742.931,
                "CIRCUIT": "Twelfth Circuit"
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -82.642837,
                            27.389737
                        ],
                        [
                            -82.37569193027889,
                            26.946040169476426
                        ],
                        [
                            -82.2553938952477,
                            26.94521438525817
                        ],
                        [
                            -82.25518374437434,
                            27.032641729544334
                        ],
                        [
                            -81.56199143066385,
                            27.033877891273548
                        ],
                        [
                            -81.56296196539765,
                            27.339846522571122
                        ],
                        [
                            -82.055753,
                            27.338264
                        ],
                        [
                            -82.054349,
                            27.646382
                        ],
                        [
                            -82.554499,
                            27.645145
                        ],
                        [
                            -82.565667,
                            27.615713
                        ],
                        [
                            -82.584629,
                            27.596021
                        ],
                        [
                            -82.611717,
                            27.585283
                        ],
                        [
                            -82.612019,
                            27.571231
                        ],
                        [
                            -82.65072,
                            27.523115
                        ],
                        [
                            -82.674621,
                            27.519614
                        ],
                        [
                            -82.686421,
                            27.497215
                        ],
                        [
                            -82.710621,
                            27.501715
                        ],
                        [
                            -82.691004,
                            27.444331
                        ],
                        [
                            -82.642837,
                            27.389737
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "id": 3,
            "type": "Feature",
            "properties": {
                "GEO_ID": "0500000US12057",
                "STATE": 12,
                "COUNTY": "Hillsborough",
                "POP": 0,
                "YOUTHPOP": 0,
                "SCREENINGS": 0,
                "CLEARCONCERN": 0,
                "POSSIBLECONCERN": 0,
                "NOCONCERN": 0,
                "SITUATION_INVOVLES_MINORS": "true",
                "INVOLVESMINORS": 0,
                "CASE_INVOLVES_ADULTS": "true",
                "INVOLVESADULTS": 0,
                "TOTAL": 0,
                "CENSUSAREA": 1020.214,
                "CIRCUIT": "Thirteenth Circuit"
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -82.105853,
                            28.17165
                        ],
                        [
                            -82.651165,
                            28.173266
                        ],
                        [
                            -82.648616,
                            27.966309
                        ],
                        [
                            -82.589419,
                            27.970898
                        ],
                        [
                            -82.553918,
                            27.966998
                        ],
                        [
                            -82.533718,
                            27.932999
                        ],
                        [
                            -82.529918,
                            27.877501
                        ],
                        [
                            -82.553946,
                            27.848462
                        ],
                        [
                            -82.511193,
                            27.828015
                        ],
                        [
                            -82.47244,
                            27.822559
                        ],
                        [
                            -82.46884,
                            27.843295
                        ],
                        [
                            -82.488057,
                            27.863566
                        ],
                        [
                            -82.489817,
                            27.9196
                        ],
                        [
                            -82.413915,
                            27.901401
                        ],
                        [
                            -82.402615,
                            27.882602
                        ],
                        [
                            -82.393383,
                            27.837519
                        ],
                        [
                            -82.402857,
                            27.812671
                        ],
                        [
                            -82.434635,
                            27.764355
                        ],
                        [
                            -82.478339,
                            27.74625
                        ],
                        [
                            -82.477638,
                            27.723004
                        ],
                        [
                            -82.514265,
                            27.705588
                        ],
                        [
                            -82.554499,
                            27.645145
                        ],
                        [
                            -82.054349,
                            27.646382
                        ],
                        [
                            -82.054035,
                            27.850683
                        ],
                        [
                            -82.056261,
                            28.171592
                        ],
                        [
                            -82.105853,
                            28.17165
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "id": 4,
            "type": "Feature",
            "properties": {
                "GEO_ID": "0500000US12105",
                "STATE": 12,
                "COUNTY": "Polk",
                "POP": 0,
                "YOUTHPOP": 0,
                "SCREENINGS": 0,
                "CLEARCONCERN": 0,
                "POSSIBLECONCERN": 0,
                "NOCONCERN": 0,
                "SITUATION_INVOVLES_MINORS": "true",
                "INVOLVESMINORS": 0,
                "CASE_INVOLVES_ADULTS": "true",
                "INVOLVESADULTS": 0,
                "TOTAL": 0,
                "CENSUSAREA": 1797.837,
                "CIRCUIT": "Tenth Circuit"
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -81.657268,
                            28.347098
                        ],
                        [
                            -81.818903,
                            28.361712
                        ],
                        [
                            -81.957641,
                            28.34518
                        ],
                        [
                            -82.055819,
                            28.312794
                        ],
                        [
                            -82.106206,
                            28.25924
                        ],
                        [
                            -82.105853,
                            28.17165
                        ],
                        [
                            -82.056261,
                            28.171592
                        ],
                        [
                            -82.054035,
                            27.850683
                        ],
                        [
                            -82.054349,
                            27.646382
                        ],
                        [
                            -82.05554447983313,
                            27.338145041308508
                        ],
                        [
                            -81.56395185183058,
                            27.340313279332307
                        ],
                        [
                            -81.56201628872981,
                            27.03390973902244
                        ],
                        [
                            -81.2684984941546,
                            27.032782561631958
                        ],
                        [
                            -81.26753071260426,
                            27.1211639492588
                        ],
                        [
                            -81.16798036707932,
                            27.123021392293253
                        ],
                        [
                            -81.1690432791901,
                            27.20955284207833
                        ],
                        [
                            -80.94401420635639,
                            27.21075583481246
                        ],
                        [
                            -80.97090494374105,
                            27.21767742856151
                        ],
                        [
                            -81.21351339294038,
                            27.528920281726393
                        ],
                        [
                            -81.142164,
                            27.643238
                        ],
                        [
                            -81.131495,
                            27.6494
                        ],
                        [
                            -81.208124,
                            27.821125
                        ],
                        [
                            -81.301862,
                            27.862081
                        ],
                        [
                            -81.308696,
                            27.92188
                        ],
                        [
                            -81.378718,
                            28.011743
                        ],
                        [
                            -81.375467,
                            28.0851
                        ],
                        [
                            -81.455315,
                            28.085367
                        ],
                        [
                            -81.455647,
                            28.142894
                        ],
                        [
                            -81.524227,
                            28.142805
                        ],
                        [
                            -81.524083,
                            28.201012
                        ],
                        [
                            -81.551345,
                            28.249045
                        ],
                        [
                            -81.657189,
                            28.259217
                        ],
                        [
                            -81.657268,
                            28.347098
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "id": 5,
            "type": "Feature",
            "properties": {
                "GEO_ID": "0500000US12103",
                "STATE": 12,
                "COUNTY": "Pinellas",
                "POP": 0,
                "YOUTHPOP": 0,
                "SCREENINGS": 0,
                "CLEARCONCERN": 0,
                "POSSIBLECONCERN": 0,
                "NOCONCERN": 0,
                "SITUATION_INVOVLES_MINORS": "true",
                "INVOLVESMINORS": 0,
                "CASE_INVOLVES_ADULTS": "true",
                "INVOLVESADULTS": 0,
                "TOTAL": 0,
                "CENSUSAREA": 273.799,
                "CIRCUIT": "Sixth Circuit"
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -82.805097,
                            28.172181
                        ],
                        [
                            -82.781324,
                            28.127591
                        ],
                        [
                            -82.782724,
                            28.055894
                        ],
                        [
                            -82.805462,
                            27.960201
                        ],
                        [
                            -82.831388,
                            27.962117
                        ],
                        [
                            -82.851126,
                            27.8863
                        ],
                        [
                            -82.846526,
                            27.854301
                        ],
                        [
                            -82.828561,
                            27.822254
                        ],
                        [
                            -82.790224,
                            27.791603
                        ],
                        [
                            -82.746223,
                            27.731306
                        ],
                        [
                            -82.738022,
                            27.706807
                        ],
                        [
                            -82.739122,
                            27.636909
                        ],
                        [
                            -82.733076,
                            27.612972
                        ],
                        [
                            -82.705017,
                            27.62531
                        ],
                        [
                            -82.723022,
                            27.671208
                        ],
                        [
                            -82.713629,
                            27.698661
                        ],
                        [
                            -82.677321,
                            27.706207
                        ],
                        [
                            -82.652521,
                            27.700307
                        ],
                        [
                            -82.63362,
                            27.710607
                        ],
                        [
                            -82.62502,
                            27.732706
                        ],
                        [
                            -82.63052,
                            27.753905
                        ],
                        [
                            -82.622723,
                            27.779868
                        ],
                        [
                            -82.586519,
                            27.816703
                        ],
                        [
                            -82.598443,
                            27.857582
                        ],
                        [
                            -82.566819,
                            27.858002
                        ],
                        [
                            -82.567919,
                            27.883701
                        ],
                        [
                            -82.61002,
                            27.873501
                        ],
                        [
                            -82.63212,
                            27.8911
                        ],
                        [
                            -82.628063,
                            27.910397
                        ],
                        [
                            -82.671221,
                            27.913
                        ],
                        [
                            -82.710022,
                            27.928299
                        ],
                        [
                            -82.716522,
                            27.958398
                        ],
                        [
                            -82.648616,
                            27.966309
                        ],
                        [
                            -82.651165,
                            28.173266
                        ],
                        [
                            -82.10588240616255,
                            28.17170814840862
                        ],
                        [
                            -82.10618801905395,
                            28.259178722860717
                        ],
                        [
                            -82.05571502280132,
                            28.313081622970483
                        ],
                        [
                            -82.05498372793036,
                            28.478480669893045
                        ],
                        [
                            -82.25319608924109,
                            28.478425035326786
                        ],
                        [
                            -82.25342448808424,
                            28.434858171145834
                        ],
                        [
                            -82.67869864075583,
                            28.433325776637787
                        ],
                        [
                            -82.70439158487034,
                            28.417503007098162
                        ],
                        [
                            -82.73445982209334,
                            28.31777612031908
                        ],
                        [
                            -82.805097,
                            28.172181
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "id": 6,
            "type": "Feature",
            "properties": {
                "GEO_ID": "0500000US12009",
                "STATE": 12,
                "COUNTY": "Brevard",
                "POP": 0,
                "YOUTHPOP": 0,
                "SCREENINGS": 0,
                "CLEARCONCERN": 0,
                "POSSIBLECONCERN": 0,
                "NOCONCERN": 0,
                "SITUATION_INVOVLES_MINORS": "true",
                "INVOLVESMINORS": 0,
                "CASE_INVOLVES_ADULTS": "true",
                "INVOLVESADULTS": 0,
                "TOTAL": 0,
                "CENSUSAREA": 1015.664,
                "CIRCUIT": "Eighteenth Circuit"
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -80.447084,
                            27.860755
                        ],
                        [
                            -80.508871,
                            27.970477
                        ],
                        [
                            -80.547675,
                            28.048795
                        ],
                        [
                            -80.566432,
                            28.09563
                        ],
                        [
                            -80.589975,
                            28.17799
                        ],
                        [
                            -80.604214,
                            28.257733
                        ],
                        [
                            -80.608074,
                            28.311285
                        ],
                        [
                            -80.603374,
                            28.363983
                        ],
                        [
                            -80.587813,
                            28.410856
                        ],
                        [
                            -80.562877,
                            28.437779
                        ],
                        [
                            -80.525094,
                            28.459454
                        ],
                        [
                            -80.560973,
                            28.530736
                        ],
                        [
                            -80.574868,
                            28.585166
                        ],
                        [
                            -80.61679,
                            28.634561
                        ],
                        [
                            -80.647288,
                            28.677875
                        ],
                        [
                            -80.709725,
                            28.756692
                        ],
                        [
                            -80.732244,
                            28.791237
                        ],
                        [
                            -80.967895,
                            28.790197
                        ],
                        [
                            -80.96445166894188,
                            28.613015898788227
                        ],
                        [
                            -80.98724388083062,
                            28.613002760466436
                        ],
                        [
                            -81.10577592473425,
                            28.82868896364173
                        ],
                        [
                            -81.17894097164363,
                            28.781850284775032
                        ],
                        [
                            -81.2165446244901,
                            28.807950563413183
                        ],
                        [
                            -81.22605589110962,
                            28.832872372154704
                        ],
                        [
                            -81.24998099754629,
                            28.833115914844537
                        ],
                        [
                            -81.36651738993322,
                            28.878832773030766
                        ],
                        [
                            -81.41416356344062,
                            28.7852191041596
                        ],
                        [
                            -81.45979014413108,
                            28.64443219602977
                        ],
                        [
                            -81.45799623406954,
                            28.640350983267187
                        ],
                        [
                            -81.32862527557445,
                            28.63932572235531
                        ],
                        [
                            -81.32695748724409,
                            28.610298246016576
                        ],
                        [
                            -81.14576164473945,
                            28.611825365692404
                        ],
                        [
                            -80.98725,
                            28.612997
                        ],
                        [
                            -80.88089,
                            28.503633
                        ],
                        [
                            -80.862908,
                            28.347487
                        ],
                        [
                            -80.868881,
                            27.822522
                        ],
                        [
                            -80.509075,
                            27.822058
                        ],
                        [
                            -80.447084,
                            27.860755
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "id": 7,
            "type": "Feature",
            "properties": {
                "GEO_ID": "0500000US12097",
                "STATE": "12",
                "COUNTY": "Osceola",
                "POP": 0,
                "YOUTHPOP": 0,
                "SCREENINGS": 0,
                "CLEARCONCERN": 0,
                "POSSIBLECONCERN": 0,
                "NOCONCERN": 0,
                "SITUATION_INVOVLES_MINORS": true,
                "INVOLVESMINORS": 0,
                "CASE_INVOLVES_ADULTS": true,
                "INVOLVESADULTS": 0,
                "TOTAL": 0,
                "CENSUSAREA": 1327.453,
                "CIRCUIT": 'Ninth Circuit' 
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -80.862908,
                            28.347487
                        ],
                        [
                            -80.8810206626121,
                            28.503366967020852
                        ],
                        [
                            -80.98708496881332,
                            28.613164027913967
                        ],
                        [
                            -81.32695499290837,
                            28.610269804027055
                        ],
                        [
                            -81.3286220625023,
                            28.63928091332648
                        ],
                        [
                            -81.45800977190432,
                            28.640343425594498
                        ],
                        [
                            -81.45992644673527,
                            28.644467014698293
                        ],
                        [
                            -81.41369355830328,
                            28.78522055054406
                        ],
                        [
                            -81.62464456095779,
                            28.786036760085643
                        ],
                        [
                            -81.65806985909569,
                            28.682723637562624
                        ],
                        [
                            -81.65766892954784,
                            28.58723597465896
                        ],
                        [
                            -81.657268,
                            28.347098
                        ],
                        [
                            -81.657189,
                            28.259217
                        ],
                        [
                            -81.551345,
                            28.249045
                        ],
                        [
                            -81.524083,
                            28.201012
                        ],
                        [
                            -81.524227,
                            28.142805
                        ],
                        [
                            -81.455647,
                            28.142894
                        ],
                        [
                            -81.455315,
                            28.085367
                        ],
                        [
                            -81.375467,
                            28.0851
                        ],
                        [
                            -81.378718,
                            28.011743
                        ],
                        [
                            -81.308696,
                            27.92188
                        ],
                        [
                            -81.301862,
                            27.862081
                        ],
                        [
                            -81.208124,
                            27.821125
                        ],
                        [
                            -81.131495,
                            27.6494
                        ],
                        [
                            -81.142164,
                            27.643238
                        ],
                        [
                            -80.87315,
                            27.642288
                        ],
                        [
                            -80.868881,
                            27.822522
                        ],
                        [
                            -80.862908,
                            28.347487
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "id": 8,
            "type": "Feature",
            "properties": {
                "GEO_ID": "0500000US12017",
                "STATE": 12,
                "COUNTY": "Citrus",
                "POP": 0,
                "YOUTHPOP": 0,
                "SCREENINGS": 0,
                "CLEARCONCERN": 0,
                "POSSIBLECONCERN": 0,
                "NOCONCERN": 0,
                "SITUATION_INVOVLES_MINORS": "true",
                "INVOLVESMINORS": 0,
                "CASE_INVOLVES_ADULTS": "true",
                "INVOLVESADULTS": 0,
                "TOTAL": 0,
                "CENSUSAREA": 581.696,
                "CIRCUIT": "Fifth Circuit"
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -82.535591,
                            29.044855
                        ],
                        [
                            -82.612653,
                            29.009271
                        ],
                        [
                            -82.689814,
                            29.033962
                        ],
                        [
                            -82.758906,
                            28.993277
                        ],
                        [
                            -82.735754,
                            28.973709
                        ],
                        [
                            -82.702618,
                            28.932955
                        ],
                        [
                            -82.688864,
                            28.905609
                        ],
                        [
                            -82.656028,
                            28.899179
                        ],
                        [
                            -82.639898,
                            28.876224
                        ],
                        [
                            -82.638657,
                            28.843344
                        ],
                        [
                            -82.652926,
                            28.830936
                        ],
                        [
                            -82.643,
                            28.789991
                        ],
                        [
                            -82.651065,
                            28.747184
                        ],
                        [
                            -82.662232,
                            28.737258
                        ],
                        [
                            -82.646411,
                            28.694434
                        ],
                        [
                            -82.67871356675649,
                            28.43333787597718
                        ],
                        [
                            -82.2534239749561,
                            28.434857846067487
                        ],
                        [
                            -82.25330184621718,
                            28.478479426898613
                        ],
                        [
                            -82.05470349653713,
                            28.478229804944945
                        ],
                        [
                            -82.05579743552654,
                            28.312834562151764
                        ],
                        [
                            -81.95757112122807,
                            28.344898178019953
                        ],
                        [
                            -81.81886015176593,
                            28.361818190683124
                        ],
                        [
                            -81.65732553824752,
                            28.34711247729386
                        ],
                        [
                            -81.65809214850525,
                            28.6827350612731
                        ],
                        [
                            -81.62473347447023,
                            28.78592848534823
                        ],
                        [
                            -81.41409248741505,
                            28.785395981767568
                        ],
                        [
                            -81.366430128412,
                            28.8789866182685
                        ],
                        [
                            -81.35447211732034,
                            28.98444288367196
                        ],
                        [
                            -81.50206992111106,
                            29.097643197679304
                        ],
                        [
                            -81.53113781359171,
                            29.176532792259646
                        ],
                        [
                            -81.61236068863835,
                            29.202887284630158
                        ],
                        [
                            -81.64213704961232,
                            29.277433716753787
                        ],
                        [
                            -81.68055220194057,
                            29.324199626479825
                        ],
                        [
                            -81.7413313414087,
                            29.37082279784957
                        ],
                        [
                            -81.77574412792069,
                            29.48724288341554
                        ],
                        [
                            -81.84288376931737,
                            29.520991934172418
                        ],
                        [
                            -82.05571779863617,
                            29.47195952969968
                        ],
                        [
                            -82.10548492784216,
                            29.435939898514203
                        ],
                        [
                            -82.21319078750047,
                            29.431764841154944
                        ],
                        [
                            -82.2253902692472,
                            29.48445131924106
                        ],
                        [
                            -82.40661420035678,
                            29.485083540755202
                        ],
                        [
                            -82.40325247715982,
                            29.21562995962359
                        ],
                        [
                            -82.53482165193522,
                            29.21467631411176
                        ],
                        [
                            -82.535591,
                            29.044855
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "id": 9,
            "type": "Feature",
            "properties": {
                "GEO_ID": "0500000US12127",
                "STATE": 12,
                "COUNTY": "Volusia",
                "POP": 0,
                "YOUTHPOP": 0,
                "SCREENINGS": 0,
                "CLEARCONCERN": 0,
                "POSSIBLECONCERN": 0,
                "NOCONCERN": 0,
                "SITUATION_INVOVLES_MINORS": "true",
                "INVOLVESMINORS": 0,
                "CASE_INVOLVES_ADULTS": "true",
                "INVOLVESADULTS": 0,
                "TOTAL": 0,
                "CENSUSAREA": 1101.032,
                "CIRCUIT": "Seventh Circuit"
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -81.36694,
                            28.879227
                        ],
                        [
                            -81.24997,
                            28.833106
                        ],
                        [
                            -81.226003,
                            28.83263
                        ],
                        [
                            -81.216345,
                            28.808302
                        ],
                        [
                            -81.178936,
                            28.781886
                        ],
                        [
                            -81.10575,
                            28.828541
                        ],
                        [
                            -80.98725,
                            28.612997
                        ],
                        [
                            -80.964466,
                            28.612992
                        ],
                        [
                            -80.967895,
                            28.790197
                        ],
                        [
                            -80.732244,
                            28.791237
                        ],
                        [
                            -80.878275,
                            29.010563
                        ],
                        [
                            -80.907275,
                            29.064262
                        ],
                        [
                            -80.944376,
                            29.110861
                        ],
                        [
                            -80.966176,
                            29.14796
                        ],
                        [
                            -81.046678,
                            29.307856
                        ],
                        [
                            -81.101923,
                            29.427055
                        ],
                        [
                            -81.37988488011526,
                            30.252915775851005
                        ],
                        [
                            -81.43728605374196,
                            30.251999111119204
                        ],
                        [
                            -81.43349956720411,
                            30.105486802934465
                        ],
                        [
                            -81.62379563563522,
                            30.13358705772294
                        ],
                        [
                            -81.6506977725944,
                            30.121526128010107
                        ],
                        [
                            -81.6799542040514,
                            30.121004328473447
                        ],
                        [
                            -81.68891165305465,
                            30.02852718520844
                        ],
                        [
                            -81.60094448723687,
                            29.95599456974997
                        ],
                        [
                            -81.5811937222293,
                            29.84018090299084
                        ],
                        [
                            -81.79715035210252,
                            29.836418046371726
                        ],
                        [
                            -81.93914634402175,
                            29.747629096403898
                        ],
                        [
                            -82.0491039667922,
                            29.718654520891338
                        ],
                        [
                            -82.05544732785671,
                            29.718125029579376
                        ],
                        [
                            -82.05581058494336,
                            29.472119431731812
                        ],
                        [
                            -81.84288075084166,
                            29.52097142175364
                        ],
                        [
                            -81.77564819055495,
                            29.487228304775847
                        ],
                        [
                            -81.74140345160694,
                            29.370938888987453
                        ],
                        [
                            -81.680903,
                            29.32443
                        ],
                        [
                            -81.641916,
                            29.276766
                        ],
                        [
                            -81.61234,
                            29.202943
                        ],
                        [
                            -81.531262,
                            29.176621
                        ],
                        [
                            -81.502055,
                            29.097802
                        ],
                        [
                            -81.354554,
                            28.984375
                        ],
                        [
                            -81.36694,
                            28.879227
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "id": 10,
            "type": "Feature",
            "properties": {
                "GEO_ID": "0500000US12075",
                "STATE": 12,
                "COUNTY": "Levy",
                "POP": 0,
                "YOUTHPOP": 0,
                "SCREENINGS": 0,
                "CLEARCONCERN": 0,
                "POSSIBLECONCERN": 0,
                "NOCONCERN": 0,
                "SITUATION_INVOVLES_MINORS": "true",
                "INVOLVESMINORS": 0,
                "CASE_INVOLVES_ADULTS": "true",
                "INVOLVESADULTS": 0,
                "TOTAL": 0,
                "CENSUSAREA": 1118.206,
                "CIRCUIT": "Eighth Circuit"
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -82.535591,
                            29.044855
                        ],
                        [
                            -82.53486,
                            29.21468
                        ],
                        [
                            -82.403237,
                            29.215623
                        ],
                        [
                            -82.40662,
                            29.485048
                        ],
                        [
                            -82.22535078974605,
                            29.484487936486648
                        ],
                        [
                            -82.21323333461878,
                            29.4317605211369
                        ],
                        [
                            -82.10567377085255,
                            29.435971715323944
                        ],
                        [
                            -82.05585486076215,
                            29.47118995875492
                        ],
                        [
                            -82.05561047730738,
                            29.71825223527859
                        ],
                        [
                            -82.04909787434129,
                            29.718548386187297
                        ],
                        [
                            -82.04609740777225,
                            29.747649114845295
                        ],
                        [
                            -82.0502696108197,
                            30.362243841938096
                        ],
                        [
                            -82.1772025192752,
                            30.375685196324262
                        ],
                        [
                            -82.19939385224022,
                            30.484888663166572
                        ],
                        [
                            -82.23485245143311,
                            30.537779888443254
                        ],
                        [
                            -82.21444887703058,
                            30.569018178849145
                        ],
                        [
                            -82.45982956273421,
                            30.58432307334285
                        ],
                        [
                            -82.45837586097645,
                            30.13656666075879
                        ],
                        [
                            -82.57602069168416,
                            29.991580364047095
                        ],
                        [
                            -82.52970943899125,
                            29.94105288548324
                        ],
                        [
                            -82.65854019239447,
                            29.830147929167232
                        ],
                        [
                            -82.80043422896341,
                            29.932129179086445
                        ],
                        [
                            -82.86475050577674,
                            29.909551934425107
                        ],
                        [
                            -82.87952764702996,
                            29.88693210457871
                        ],
                        [
                            -82.87553397173357,
                            29.866287568129778
                        ],
                        [
                            -82.89276664694312,
                            29.82750180340374
                        ],
                        [
                            -82.92056611853536,
                            29.82404050444249
                        ],
                        [
                            -82.94986737008547,
                            29.755179727221446
                        ],
                        [
                            -82.937374,
                            29.591312
                        ],
                        [
                            -82.984371,
                            29.471004
                        ],
                        [
                            -83.068522,
                            29.343953
                        ],
                        [
                            -83.166091,
                            29.28888
                        ],
                        [
                            -83.128027,
                            29.282733
                        ],
                        [
                            -83.089013,
                            29.266502
                        ],
                        [
                            -83.074734,
                            29.247975
                        ],
                        [
                            -83.087839,
                            29.21642
                        ],
                        [
                            -83.060947,
                            29.170959
                        ],
                        [
                            -83.068249,
                            29.153135
                        ],
                        [
                            -83.053207,
                            29.130839
                        ],
                        [
                            -83.030453,
                            29.134023
                        ],
                        [
                            -82.996144,
                            29.178074
                        ],
                        [
                            -82.945302,
                            29.167821
                        ],
                        [
                            -82.827073,
                            29.158425
                        ],
                        [
                            -82.804736,
                            29.146624
                        ],
                        [
                            -82.801166,
                            29.105103
                        ],
                        [
                            -82.823659,
                            29.098902
                        ],
                        [
                            -82.816925,
                            29.076215
                        ],
                        [
                            -82.780558,
                            29.07358
                        ],
                        [
                            -82.759704,
                            29.054192
                        ],
                        [
                            -82.753513,
                            29.026496
                        ],
                        [
                            -82.758906,
                            28.993277
                        ],
                        [
                            -82.689814,
                            29.033962
                        ],
                        [
                            -82.612653,
                            29.009271
                        ],
                        [
                            -82.535591,
                            29.044855
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "id": 11,
            "type": "Feature",
            "properties": {
                "GEO_ID": "0500000US12089",
                "STATE": 12,
                "COUNTY": "Nassau",
                "POP": 0,
                "YOUTHPOP": 0,
                "SCREENINGS": 0,
                "CLEARCONCERN": 0,
                "POSSIBLECONCERN": 0,
                "NOCONCERN": 0,
                "SITUATION_INVOVLES_MINORS": "true",
                "INVOLVESMINORS": 0,
                "CASE_INVOLVES_ADULTS": "true",
                "INVOLVESADULTS": 0,
                "TOTAL": 0,
                "CENSUSAREA": 648.639,
                "CIRCUIT": "Fourth Circuit"
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -81.44438425265935,
                            30.50440511593775
                        ],
                        [
                            -81.434064,
                            30.522569
                        ],
                        [
                            -81.442564,
                            30.555189
                        ],
                        [
                            -81.443099,
                            30.600938
                        ],
                        [
                            -81.42742,
                            30.69802
                        ],
                        [
                            -81.444124,
                            30.709714
                        ],
                        [
                            -81.544618,
                            30.712636
                        ],
                        [
                            -81.606221,
                            30.718135
                        ],
                        [
                            -81.637222,
                            30.733835
                        ],
                        [
                            -81.6785745,
                            30.739234500000002
                        ],
                        [
                            -81.719927,
                            30.744634
                        ],
                        [
                            -81.7429389652951,
                            30.768865555429116
                        ],
                        [
                            -81.79308745009217,
                            30.789445190403857
                        ],
                        [
                            -81.83871403470535,
                            30.792329260436663
                        ],
                        [
                            -81.86805434490142,
                            30.796083152334187
                        ],
                        [
                            -81.89466407048585,
                            30.83049621399313
                        ],
                        [
                            -81.94495874401115,
                            30.832036789639346
                        ],
                        [
                            -81.9669225639592,
                            30.81699768342029
                        ],
                        [
                            -81.97541302599075,
                            30.778327242000085
                        ],
                        [
                            -82.00817149456758,
                            30.79537717343669
                        ],
                        [
                            -82.02791906833566,
                            30.787456861910954
                        ],
                        [
                            -82.0151616351552,
                            30.76029608171058
                        ],
                        [
                            -82.03839178336204,
                            30.75264059841536
                        ],
                        [
                            -82.04554256779812,
                            30.726864357797027
                        ],
                        [
                            -82.050432,
                            30.676266
                        ],
                        [
                            -82.049401,
                            30.655296
                        ],
                        [
                            -82.01329,
                            30.595665
                        ],
                        [
                            -82.005477,
                            30.563495
                        ],
                        [
                            -82.016103,
                            30.497355
                        ],
                        [
                            -82.050031,
                            30.36249
                        ],
                        [
                            -82.049236,
                            30.273432
                        ],
                        [
                            -82.04611280801768,
                            29.747232683421757
                        ],
                        [
                            -82.04910020293515,
                            29.718572377123067
                        ],
                        [
                            -81.9392772176191,
                            29.7475749498485
                        ],
                        [
                            -81.79726908433578,
                            29.836602580152412
                        ],
                        [
                            -81.5812051623574,
                            29.840165802616042
                        ],
                        [
                            -81.6009555416712,
                            29.955936047536305
                        ],
                        [
                            -81.68894347861082,
                            30.028370690189046
                        ],
                        [
                            -81.68016863584877,
                            30.121211067532386
                        ],
                        [
                            -81.65061909240792,
                            30.121524661931332
                        ],
                        [
                            -81.62383381960522,
                            30.13363345741914
                        ],
                        [
                            -81.43375232802671,
                            30.105939196392217
                        ],
                        [
                            -81.4368892455384,
                            30.252314767088897
                        ],
                        [
                            -81.37989195813851,
                            30.25291094516258
                        ],
                        [
                            -81.39253968868003,
                            30.39525775721127
                        ],
                        [
                            -81.41312779513768,
                            30.493506512596564
                        ],
                        [
                            -81.44438425265935,
                            30.50440511593775
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "id": 12,
            "type": "Feature",
            "properties": {
                "GEO_ID": "0500000US12123",
                "STATE": 12,
                "COUNTY": "Taylor",
                "POP": 0,
                "YOUTHPOP": 0,
                "SCREENINGS": 0,
                "CLEARCONCERN": 0,
                "POSSIBLECONCERN": 0,
                "NOCONCERN": 0,
                "SITUATION_INVOVLES_MINORS": "true",
                "INVOLVESMINORS": 0,
                "CASE_INVOLVES_ADULTS": "true",
                "INVOLVESADULTS": 0,
                "TOTAL": 0,
                "CENSUSAREA": 1043.305,
                "CIRCUIT": "Third Circuit"
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -83.820235,
                            30.30354
                        ],
                        [
                            -83.829465,
                            30.287863
                        ],
                        [
                            -83.915533,
                            30.245386
                        ],
                        [
                            -83.991607,
                            30.08392
                        ],
                        [
                            -83.933432,
                            30.046305
                        ],
                        [
                            -83.93151,
                            30.039068
                        ],
                        [
                            -83.845427,
                            29.998068
                        ],
                        [
                            -83.82869,
                            29.983187
                        ],
                        [
                            -83.788729,
                            29.976982
                        ],
                        [
                            -83.686423,
                            29.923735
                        ],
                        [
                            -83.63798,
                            29.886073
                        ],
                        [
                            -83.618568,
                            29.842336
                        ],
                        [
                            -83.585899,
                            29.811754
                        ],
                        [
                            -83.586089,
                            29.784644
                        ],
                        [
                            -83.537645,
                            29.72306
                        ],
                        [
                            -83.493728,
                            29.708388
                        ],
                        [
                            -83.483143,
                            29.690478
                        ],
                        [
                            -83.455356,
                            29.676444
                        ],
                        [
                            -83.412278,
                            29.666922
                        ],
                        [
                            -83.3994385705594,
                            29.518499600093385
                        ],
                        [
                            -83.40146188158992,
                            29.502669352427986
                        ],
                        [
                            -83.33345761259613,
                            29.470129025205797
                        ],
                        [
                            -83.28491475312502,
                            29.427547929114972
                        ],
                        [
                            -83.23059807601416,
                            29.42321034780946
                        ],
                        [
                            -83.1660631469775,
                            29.288891326079217
                        ],
                        [
                            -83.0685459998149,
                            29.34390060735838
                        ],
                        [
                            -82.98438869382757,
                            29.47102267591495
                        ],
                        [
                            -82.93738091910163,
                            29.59114571500435
                        ],
                        [
                            -82.94974146352135,
                            29.75447995774536
                        ],
                        [
                            -82.92061051654545,
                            29.82399680063004
                        ],
                        [
                            -82.89275308270055,
                            29.8274927578096
                        ],
                        [
                            -82.87556671615019,
                            29.866549691832457
                        ],
                        [
                            -82.87979057561195,
                            29.88680818561251
                        ],
                        [
                            -82.8642372328433,
                            29.909849514111784
                        ],
                        [
                            -82.80044865629122,
                            29.932137807330864
                        ],
                        [
                            -82.6585273876247,
                            29.83016136561225
                        ],
                        [
                            -82.52971367831944,
                            29.94090413657438
                        ],
                        [
                            -82.57601745756945,
                            29.991606366848636
                        ],
                        [
                            -82.45834591971081,
                            30.13645415129393
                        ],
                        [
                            -82.4598323435274,
                            30.584377398726517
                        ],
                        [
                            -83.61179396023702,
                            30.65128468169012
                        ],
                        [
                            -83.60420482711096,
                            30.581558849432025
                        ],
                        [
                            -83.73130764635445,
                            30.492076681157698
                        ],
                        [
                            -83.72182203515429,
                            30.434404720558078
                        ],
                        [
                            -83.80593962280282,
                            30.372704079877643
                        ],
                        [
                            -83.820235,
                            30.30354
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "id": 13,
            "type": "Feature",
            "properties": {
                "GEO_ID": "0500000US12037",
                "STATE": 12,
                "COUNTY": "Franklin",
                "POP": 0,
                "YOUTHPOP": 0,
                "SCREENINGS": 0,
                "CLEARCONCERN": 0,
                "POSSIBLECONCERN": 0,
                "NOCONCERN": 0,
                "SITUATION_INVOVLES_MINORS": "true",
                "INVOLVESMINORS": 0,
                "CASE_INVOLVES_ADULTS": "true",
                "INVOLVESADULTS": 0,
                "TOTAL": 0,
                "CENSUSAREA": 534.725,
                "CIRCUIT": "Second Circuit"
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            [
                                -85.156415,
                                29.679628
                            ],
                            [
                                -85.208981,
                                29.681775
                            ],
                            [
                                -85.142746,
                                29.635404
                            ],
                            [
                                -85.097218,
                                29.633004
                            ],
                            [
                                -85.077237,
                                29.670862
                            ],
                            [
                                -85.114268,
                                29.688658
                            ],
                            [
                                -85.156415,
                                29.679628
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -83.61178151950111,
                                30.65134109408144
                            ],
                            [
                                -84.86466829197752,
                                30.711335848242165
                            ],
                            [
                                -84.93285965281692,
                                30.604860717789304
                            ],
                            [
                                -84.99006681519838,
                                30.525257008566925
                            ],
                            [
                                -84.98205518694415,
                                30.442675429711795
                            ],
                            [
                                -85.05759361243697,
                                30.26555248781849
                            ],
                            [
                                -85.11388400077989,
                                30.20153700019786
                            ],
                            [
                                -85.15412270360534,
                                30.09162881012839
                            ],
                            [
                                -85.13273364347447,
                                30.054893441478107
                            ],
                            [
                                -85.11381407342157,
                                30.021901388253163
                            ],
                            [
                                -85.025439,
                                29.971399
                            ],
                            [
                                -85.012191,
                                29.873167
                            ],
                            [
                                -85.040078,
                                29.779346
                            ],
                            [
                                -85.109401,
                                29.776793
                            ],
                            [
                                -85.217355,
                                29.694953
                            ],
                            [
                                -85.177284,
                                29.700193
                            ],
                            [
                                -85.101682,
                                29.718748
                            ],
                            [
                                -85.037212,
                                29.711074
                            ],
                            [
                                -84.993264,
                                29.714961
                            ],
                            [
                                -84.964007,
                                29.742422
                            ],
                            [
                                -84.93837,
                                29.750211
                            ],
                            [
                                -84.91511,
                                29.783303
                            ],
                            [
                                -84.877111,
                                29.772888
                            ],
                            [
                                -84.901781,
                                29.735723
                            ],
                            [
                                -84.824197,
                                29.758288
                            ],
                            [
                                -84.762998,
                                29.788846
                            ],
                            [
                                -84.692053,
                                29.829059
                            ],
                            [
                                -84.669728,
                                29.82891
                            ],
                            [
                                -84.603303,
                                29.876117
                            ],
                            [
                                -84.535873,
                                29.910092
                            ],
                            [
                                -84.494562,
                                29.913957
                            ],
                            [
                                -84.451705,
                                29.929085
                            ],
                            [
                                -84.434287,
                                29.906328
                            ],
                            [
                                -84.378937,
                                29.893112
                            ],
                            [
                                -84.343389,
                                29.899539
                            ],
                            [
                                -84.333746,
                                29.923721
                            ],
                            [
                                -84.341261,
                                29.960775
                            ],
                            [
                                -84.33800556399527,
                                29.97030073458719
                            ],
                            [
                                -84.34779575910817,
                                30.012560100464835
                            ],
                            [
                                -84.32713818591309,
                                30.041636387968936
                            ],
                            [
                                -84.29588850215283,
                                30.053298113855973
                            ],
                            [
                                -84.26859436673824,
                                30.06180179279628
                            ],
                            [
                                -84.26443521217075,
                                30.099409747472123
                            ],
                            [
                                -84.2455279198289,
                                30.08226120009414
                            ],
                            [
                                -84.16804254134377,
                                30.06874565219193
                            ],
                            [
                                -84.08220096049952,
                                30.092039968687693
                            ],
                            [
                                -84.02453203230259,
                                30.102450750600703
                            ],
                            [
                                -83.99160191585644,
                                30.084048457134294
                            ],
                            [
                                -83.91577539249816,
                                30.24525993562948
                            ],
                            [
                                -83.82934310423208,
                                30.28797644357914
                            ],
                            [
                                -83.8203441666534,
                                30.303479060143225
                            ],
                            [
                                -83.80587149716048,
                                30.37282465897792
                            ],
                            [
                                -83.72183230617867,
                                30.434422024785068
                            ],
                            [
                                -83.73132270340189,
                                30.492074907978868
                            ],
                            [
                                -83.60420064168427,
                                30.58155466853413
                            ],
                            [
                                -83.61178151950111,
                                30.65134109408144
                            ]
                        ]
                    ]
                ],
                "type": "MultiPolygon"
            }
        },
        {
            "id": 14,
            "type": "Feature",
            "properties": {
                "GEO_ID": "0500000US12045",
                "STATE": 12,
                "COUNTY": "Gulf",
                "POP": 0,
                "YOUTHPOP": 0,
                "SCREENINGS": 0,
                "CLEARCONCERN": 0,
                "POSSIBLECONCERN": 0,
                "NOCONCERN": 0,
                "SITUATION_INVOVLES_MINORS": "true",
                "INVOLVESMINORS": 0,
                "CASE_INVOLVES_ADULTS": "true",
                "INVOLVESADULTS": 0,
                "TOTAL": 0,
                "CENSUSAREA": 564.012,
                "CIRCUIT": "Fourteenth Circuit"
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -85.11566,
                            30.199976
                        ],
                        [
                            -85.05699671278192,
                            30.265361113872203
                        ],
                        [
                            -84.98217510954521,
                            30.442526766828554
                        ],
                        [
                            -84.99025426755453,
                            30.52516968931193
                        ],
                        [
                            -84.93235007174724,
                            30.605605505155538
                        ],
                        [
                            -84.86472481237116,
                            30.71129112262465
                        ],
                        [
                            -84.89581446310135,
                            30.749567982792115
                        ],
                        [
                            -84.94106886988624,
                            30.88680034919263
                        ],
                        [
                            -84.98372182336402,
                            30.936375372796697
                        ],
                        [
                            -84.99854910000087,
                            30.972118696247378
                        ],
                        [
                            -85.00210045685995,
                            31.001123324016575
                        ],
                        [
                            -85.49806916831268,
                            30.996857443654065
                        ],
                        [
                            -86.0370903908388,
                            30.99181886541142
                        ],
                        [
                            -86.036595359511,
                            30.705674326278817
                        ],
                        [
                            -85.84408383718007,
                            30.701806326374797
                        ],
                        [
                            -85.91606438602167,
                            30.637329321692924
                        ],
                        [
                            -85.85957163919284,
                            30.49650946385157
                        ],
                        [
                            -85.99330530848079,
                            30.389253099058656
                        ],
                        [
                            -85.9959947977435,
                            30.267705133115903
                        ],
                        [
                            -85.84576059293842,
                            30.20117885500752
                        ],
                        [
                            -85.72031417350166,
                            30.11019394650033
                        ],
                        [
                            -85.60532590378571,
                            30.055502718927244
                        ],
                        [
                            -85.50524542374448,
                            29.969490658216465
                        ],
                        [
                            -85.48554859688208,
                            29.95469123680893
                        ],
                        [
                            -85.46778751298264,
                            29.956662475648372
                        ],
                        [
                            -85.42452237333191,
                            29.948865682149158
                        ],
                        [
                            -85.388677,
                            29.924355
                        ],
                        [
                            -85.363731,
                            29.898915
                        ],
                        [
                            -85.336654,
                            29.849295
                        ],
                        [
                            -85.312911,
                            29.832273
                        ],
                        [
                            -85.301331,
                            29.797117
                        ],
                        [
                            -85.31139,
                            29.697557
                        ],
                        [
                            -85.353885,
                            29.684765
                        ],
                        [
                            -85.37796,
                            29.709621
                        ],
                        [
                            -85.405907,
                            29.80193
                        ],
                        [
                            -85.413983,
                            29.799865
                        ],
                        [
                            -85.397871,
                            29.740498
                        ],
                        [
                            -85.380303,
                            29.698485
                        ],
                        [
                            -85.352615,
                            29.659787
                        ],
                        [
                            -85.319215,
                            29.681494
                        ],
                        [
                            -85.259719,
                            29.681296
                        ],
                        [
                            -85.217355,
                            29.694953
                        ],
                        [
                            -85.109401,
                            29.776793
                        ],
                        [
                            -85.040078,
                            29.779346
                        ],
                        [
                            -85.012191,
                            29.873167
                        ],
                        [
                            -85.025439,
                            29.971399
                        ],
                        [
                            -85.113691,
                            30.023173
                        ],
                        [
                            -85.153773,
                            30.091294
                        ],
                        [
                            -85.11566,
                            30.199976
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "id": 15,
            "type": "Feature",
            "properties": {
                "GEO_ID": "0500000US12033",
                "STATE": 12,
                "COUNTY": "Escambia",
                "POP": 0,
                "YOUTHPOP": 0,
                "SCREENINGS": 0,
                "CLEARCONCERN": 0,
                "POSSIBLECONCERN": 0,
                "NOCONCERN": 0,
                "SITUATION_INVOVLES_MINORS": "true",
                "INVOLVESMINORS": 0,
                "CASE_INVOLVES_ADULTS": "true",
                "INVOLVESADULTS": 0,
                "TOTAL": 0,
                "CENSUSAREA": 656.461,
                "CIRCUIT": "First Circuit"
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -86.919292,
                            30.370675
                        ],
                        [
                            -86.6987913269517,
                            30.396475073714704
                        ],
                        [
                            -86.50613860025508,
                            30.383839355779365
                        ],
                        [
                            -86.19592420799205,
                            30.335008826720326
                        ],
                        [
                            -85.9971261728096,
                            30.268378809811917
                        ],
                        [
                            -85.99319483837508,
                            30.388131492097738
                        ],
                        [
                            -85.85890851735324,
                            30.497167962169517
                        ],
                        [
                            -85.91610468248115,
                            30.637098918494015
                        ],
                        [
                            -85.844190959436,
                            30.70251470680915
                        ],
                        [
                            -86.0365092088678,
                            30.705415365361354
                        ],
                        [
                            -86.03663457012722,
                            30.991889570370326
                        ],
                        [
                            -87.598928,
                            30.997457
                        ],
                        [
                            -87.594111,
                            30.976335
                        ],
                        [
                            -87.601355,
                            30.936294
                        ],
                        [
                            -87.634938,
                            30.865886
                        ],
                        [
                            -87.624137,
                            30.845713
                        ],
                        [
                            -87.532607,
                            30.743489
                        ],
                        [
                            -87.481225,
                            30.716508
                        ],
                        [
                            -87.407118,
                            30.671796
                        ],
                        [
                            -87.394479,
                            30.625192
                        ],
                        [
                            -87.449921,
                            30.51469
                        ],
                        [
                            -87.430578,
                            30.491096
                        ],
                        [
                            -87.419177,
                            30.410198
                        ],
                        [
                            -87.440678,
                            30.391498
                        ],
                        [
                            -87.451378,
                            30.367199
                        ],
                        [
                            -87.452378,
                            30.300201
                        ],
                        [
                            -87.419859,
                            30.297128
                        ],
                        [
                            -87.295422,
                            30.323503
                        ],
                        [
                            -87.267827,
                            30.31548
                        ],
                        [
                            -87.155392,
                            30.327748
                        ],
                        [
                            -86.919292,
                            30.370675
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
        {
            "id": 16,
            "type": "Feature",
            "properties": {
                "GEO_ID": "0500000US12011",
                "STATE": 12,
                "COUNTY": "Broward",
                "POP": 0,
                "YOUTHPOP": 0,
                "SCREENINGS": 0,
                "CLEARCONCERN": 0,
                "POSSIBLECONCERN": 0,
                "NOCONCERN": 0,
                "SITUATION_INVOVLES_MINORS": "true",
                "INVOLVESMINORS": 0,
                "CASE_INVOLVES_ADULTS": "true",
                "INVOLVESADULTS": 0,
                "TOTAL": 0,
                "CENSUSAREA": 1209.785,
                "CIRCUIT": "Seventeenth Circuit"
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -80.872932,
                            25.979434
                        ],
                        [
                            -80.680038,
                            25.978749
                        ],
                        [
                            -80.680016,
                            25.956857
                        ],
                        [
                            -80.294972,
                            25.95677
                        ],
                        [
                            -80.295187,
                            25.97057
                        ],
                        [
                            -80.117798,
                            25.975152
                        ],
                        [
                            -80.109566,
                            26.087165
                        ],
                        [
                            -80.089365,
                            26.231859
                        ],
                        [
                            -80.079865,
                            26.264358
                        ],
                        [
                            -80.074837,
                            26.321032
                        ],
                        [
                            -80.099747,
                            26.327668
                        ],
                        [
                            -80.15377,
                            26.327766
                        ],
                        [
                            -80.296935,
                            26.334356
                        ],
                        [
                            -80.881233,
                            26.333806
                        ],
                        [
                            -80.879809,
                            26.259455
                        ],
                        [
                            -80.872932,
                            25.979434
                        ]
                    ]
                ],
                "type": "Polygon"
            },
        },
        {
            "id": 17,
            "type": "Feature",
            "properties": {
                "GEO_ID": "0500000US12086",
                "STATE": 12,
                "COUNTY": "Miami-Dade",
                "POP": 0,
                "YOUTHPOP": 0,
                "SCREENINGS": 0,
                "CLEARCONCERN": 0,
                "POSSIBLECONCERN": 0,
                "NOCONCERN": 0,
                "SITUATION_INVOVLES_MINORS": "true",
                "INVOLVESMINORS": 0,
                "CASE_INVOLVES_ADULTS": "true",
                "INVOLVESADULTS": 0,
                "TOTAL": 0,
                "CENSUSAREA": 1897.716,
                "CIRCUIT": "Eleventh Circuit"
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -80.117798,
                            25.975152
                        ],
                        [
                            -80.295187,
                            25.97057
                        ],
                        [
                            -80.294972,
                            25.95677
                        ],
                        [
                            -80.680016,
                            25.956857
                        ],
                        [
                            -80.680038,
                            25.978749
                        ],
                        [
                            -80.872932,
                            25.979434
                        ],
                        [
                            -80.873096,
                            25.805377
                        ],
                        [
                            -80.87319,
                            25.363993
                        ],
                        [
                            -80.858167,
                            25.176576
                        ],
                        [
                            -80.82653,
                            25.160478
                        ],
                        [
                            -80.796219,
                            25.17245
                        ],
                        [
                            -80.757581,
                            25.166734
                        ],
                        [
                            -80.742877,
                            25.142646
                        ],
                        [
                            -80.703718,
                            25.139115
                        ],
                        [
                            -80.683909,
                            25.166491
                        ],
                        [
                            -80.619024,
                            25.177328
                        ],
                        [
                            -80.584771,
                            25.200665
                        ],
                        [
                            -80.589186,
                            25.223796
                        ],
                        [
                            -80.570785,
                            25.239366
                        ],
                        [
                            -80.548491,
                            25.236535
                        ],
                        [
                            -80.495341,
                            25.199463
                        ],
                        [
                            -80.467824,
                            25.23254
                        ],
                        [
                            -80.385978,
                            25.288289
                        ],
                        [
                            -80.374116,
                            25.31735
                        ],
                        [
                            -80.335269,
                            25.338701
                        ],
                        [
                            -80.306801,
                            25.384369
                        ],
                        [
                            -80.32578,
                            25.39801
                        ],
                        [
                            -80.328152,
                            25.443084
                        ],
                        [
                            -80.339421,
                            25.499427
                        ],
                        [
                            -80.328746,
                            25.53264
                        ],
                        [
                            -80.313918,
                            25.539164
                        ],
                        [
                            -80.302057,
                            25.567632
                        ],
                        [
                            -80.301464,
                            25.613299
                        ],
                        [
                            -80.277147,
                            25.637022
                        ],
                        [
                            -80.250459,
                            25.688028
                        ],
                        [
                            -80.244528,
                            25.717089
                        ],
                        [
                            -80.229107,
                            25.732509
                        ],
                        [
                            -80.197674,
                            25.74437
                        ],
                        [
                            -80.172765,
                            25.737847
                        ],
                        [
                            -80.164461,
                            25.721833
                        ],
                        [
                            -80.176916,
                            25.685062
                        ],
                        [
                            -80.154972,
                            25.66549
                        ],
                        [
                            -80.144,
                            25.740812
                        ],
                        [
                            -80.127987,
                            25.772245
                        ],
                        [
                            -80.119684,
                            25.841043
                        ],
                        [
                            -80.117798,
                            25.975152
                        ]
                    ]
                ],
                "type": "Polygon"
            },
        },
        {
            "id": 18,
            "type": "Feature",
            "properties": {
                "GEO_ID": "0500000US12087",
                "STATE": 12,
                "COUNTY": "Monroe",
                "POP": 0,
                "YOUTHPOP": 0,
                "SCREENINGS": 0,
                "CLEARCONCERN": 0,
                "POSSIBLECONCERN": 0,
                "NOCONCERN": 0,
                "SITUATION_INVOVLES_MINORS": "true",
                "INVOLVESMINORS": 0,
                "CASE_INVOLVES_ADULTS": "true",
                "INVOLVESADULTS": 0,
                "TOTAL": 0,
                "CENSUSAREA": 983.282,
                "CIRCUIT": "Sixteenth Circuit"
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            [
                                -80.250581,
                                25.34193
                            ],
                            [
                                -80.221991,
                                25.397417
                            ],
                            [
                                -80.204198,
                                25.412244
                            ],
                            [
                                -80.184033,
                                25.468587
                            ],
                            [
                                -80.192336,
                                25.473331
                            ],
                            [
                                -80.21428,
                                25.416988
                            ],
                            [
                                -80.246307,
                                25.398603
                            ],
                            [
                                -80.256982,
                                25.361239
                            ],
                            [
                                -80.292567,
                                25.314385
                            ],
                            [
                                -80.342683,
                                25.268125
                            ],
                            [
                                -80.333787,
                                25.253891
                            ],
                            [
                                -80.3498,
                                25.210595
                            ],
                            [
                                -80.391909,
                                25.19221
                            ],
                            [
                                -80.387164,
                                25.170859
                            ],
                            [
                                -80.41326,
                                25.137053
                            ],
                            [
                                -80.447659,
                                25.147729
                            ],
                            [
                                -80.433575,
                                25.106317
                            ],
                            [
                                -80.524498,
                                25.016945
                            ],
                            [
                                -80.545971,
                                25.01477
                            ],
                            [
                                -80.537995,
                                24.990244
                            ],
                            [
                                -80.494781,
                                25.023019
                            ],
                            [
                                -80.493881,
                                25.038502
                            ],
                            [
                                -80.428318,
                                25.095547
                            ],
                            [
                                -80.399767,
                                25.108536
                            ],
                            [
                                -80.349855,
                                25.168825
                            ],
                            [
                                -80.351399,
                                25.190615
                            ],
                            [
                                -80.250581,
                                25.34193
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -81.582923,
                                24.658732
                            ],
                            [
                                -81.614529,
                                24.650584
                            ],
                            [
                                -81.637087,
                                24.621408
                            ],
                            [
                                -81.678595,
                                24.597647
                            ],
                            [
                                -81.794057,
                                24.586
                            ],
                            [
                                -81.814446,
                                24.56358
                            ],
                            [
                                -81.810333,
                                24.544701
                            ],
                            [
                                -81.691575,
                                24.559886
                            ],
                            [
                                -81.664209,
                                24.573143
                            ],
                            [
                                -81.602998,
                                24.586444
                            ],
                            [
                                -81.54645,
                                24.614895
                            ],
                            [
                                -81.511165,
                                24.625135
                            ],
                            [
                                -81.49858,
                                24.66498
                            ],
                            [
                                -81.480951,
                                24.645121
                            ],
                            [
                                -81.448623,
                                24.640172
                            ],
                            [
                                -81.414187,
                                24.647167
                            ],
                            [
                                -81.395096,
                                24.621062
                            ],
                            [
                                -81.303113,
                                24.651665
                            ],
                            [
                                -81.319282,
                                24.701238
                            ],
                            [
                                -81.36041,
                                24.708788
                            ],
                            [
                                -81.38558,
                                24.726182
                            ],
                            [
                                -81.392947,
                                24.743371
                            ],
                            [
                                -81.4527,
                                24.736209
                            ],
                            [
                                -81.451881,
                                24.714518
                            ],
                            [
                                -81.5124,
                                24.703737
                            ],
                            [
                                -81.535323,
                                24.67954
                            ],
                            [
                                -81.562917,
                                24.692912
                            ],
                            [
                                -81.582923,
                                24.658732
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -82.15068,
                                24.576331
                            ],
                            [
                                -82.159439,
                                24.548212
                            ],
                            [
                                -82.116787,
                                24.549144
                            ],
                            [
                                -82.099417,
                                24.572522
                            ],
                            [
                                -82.104187,
                                24.588256
                            ],
                            [
                                -82.143075,
                                24.593395
                            ],
                            [
                                -82.15068,
                                24.576331
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -80.909954,
                                24.781154
                            ],
                            [
                                -80.986454,
                                24.752749
                            ],
                            [
                                -81.02217,
                                24.733091
                            ],
                            [
                                -81.041797,
                                24.742965
                            ],
                            [
                                -81.066816,
                                24.723926
                            ],
                            [
                                -81.125371,
                                24.708291
                            ],
                            [
                                -81.108041,
                                24.688592
                            ],
                            [
                                -81.078439,
                                24.692382
                            ],
                            [
                                -81.064554,
                                24.715453
                            ],
                            [
                                -81.03429,
                                24.727341
                            ],
                            [
                                -81.015933,
                                24.719881
                            ],
                            [
                                -80.938543,
                                24.767535
                            ],
                            [
                                -80.909954,
                                24.781154
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -81.317673,
                                24.75729
                            ],
                            [
                                -81.350162,
                                24.746524
                            ],
                            [
                                -81.326844,
                                24.728375
                            ],
                            [
                                -81.288259,
                                24.720881
                            ],
                            [
                                -81.305468,
                                24.756612
                            ],
                            [
                                -81.317673,
                                24.75729
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -80.788263,
                                24.824218
                            ],
                            [
                                -80.79278,
                                24.843918
                            ],
                            [
                                -80.830158,
                                24.81428
                            ],
                            [
                                -80.796053,
                                24.81194
                            ],
                            [
                                -80.788263,
                                24.824218
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                -80.873096,
                                25.805377
                            ],
                            [
                                -81.344564,
                                25.803322
                            ],
                            [
                                -81.340406,
                                25.786631
                            ],
                            [
                                -81.361875,
                                25.772715
                            ],
                            [
                                -81.343984,
                                25.747668
                            ],
                            [
                                -81.346078,
                                25.721473
                            ],
                            [
                                -81.328935,
                                25.717233
                            ],
                            [
                                -81.290328,
                                25.687506
                            ],
                            [
                                -81.240677,
                                25.613629
                            ],
                            [
                                -81.225557,
                                25.55847
                            ],
                            [
                                -81.203175,
                                25.53416
                            ],
                            [
                                -81.208201,
                                25.504937
                            ],
                            [
                                -81.168652,
                                25.463848
                            ],
                            [
                                -81.146765,
                                25.407577
                            ],
                            [
                                -81.150508,
                                25.387255
                            ],
                            [
                                -81.128492,
                                25.380511
                            ],
                            [
                                -81.117265,
                                25.354953
                            ],
                            [
                                -81.151916,
                                25.324766
                            ],
                            [
                                -81.16207,
                                25.289833
                            ],
                            [
                                -81.172044,
                                25.222276
                            ],
                            [
                                -81.155481,
                                25.208098
                            ],
                            [
                                -81.141024,
                                25.163868
                            ],
                            [
                                -81.079859,
                                25.118797
                            ],
                            [
                                -81.050505,
                                25.128273
                            ],
                            [
                                -80.999176,
                                25.124222
                            ],
                            [
                                -80.971765,
                                25.133958
                            ],
                            [
                                -80.900577,
                                25.139669
                            ],
                            [
                                -80.900066,
                                25.162034
                            ],
                            [
                                -80.858167,
                                25.176576
                            ],
                            [
                                -80.87319,
                                25.363993
                            ],
                            [
                                -80.873096,
                                25.805377
                            ]
                        ]
                    ]
                ],
                "type": "MultiPolygon"
            },
        },
        {
            "id": 19,
            "type": "Feature",
            "properties": {
                "GEO_ID": "0500000US12099",
                "STATE": 12,
                "COUNTY": "Palm Beach",
                "POP": 0,
                "YOUTHPOP": 0,
                "SCREENINGS": 0,
                "CLEARCONCERN": 0,
                "POSSIBLECONCERN": 0,
                "NOCONCERN": 0,
                "SITUATION_INVOVLES_MINORS": "true",
                "INVOLVESMINORS": 0,
                "CASE_INVOLVES_ADULTS": "true",
                "INVOLVESADULTS": 0,
                "TOTAL": 0,
                "CENSUSAREA": 1969.763,
                "CIRCUIT": "Fifteenth Circuit"
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -80.881233,
                            26.333806
                        ],
                        [
                            -80.296935,
                            26.334356
                        ],
                        [
                            -80.15377,
                            26.327766
                        ],
                        [
                            -80.099747,
                            26.327668
                        ],
                        [
                            -80.074837,
                            26.321032
                        ],
                        [
                            -80.070564,
                            26.336455
                        ],
                        [
                            -80.060564,
                            26.444652
                        ],
                        [
                            -80.038863,
                            26.569347
                        ],
                        [
                            -80.032862,
                            26.715242
                        ],
                        [
                            -80.037462,
                            26.76634
                        ],
                        [
                            -80.031362,
                            26.796339
                        ],
                        [
                            -80.046263,
                            26.859238
                        ],
                        [
                            -80.079531,
                            26.9705
                        ],
                        [
                            -80.246728,
                            26.957189
                        ],
                        [
                            -80.38469,
                            26.958382
                        ],
                        [
                            -80.885639,
                            26.958919
                        ],
                        [
                            -80.886099,
                            26.72548
                        ],
                        [
                            -80.881233,
                            26.333806
                        ]
                    ]
                ],
                "type": "Polygon"
            },
        }
    ]
}