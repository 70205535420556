import React, { useEffect, useState, useCallback, Fragment } from "react";
import { API } from "aws-amplify";
import uniq from "lodash/uniq";
import throttle from "lodash/throttle";
import Markdown from "react-markdown";

import Layout from "./Layout";
import LoginCTABlock from "../../Components/PublicSite/CTABlock/LoginCTABlock";
import Hero from "../../Components/PublicSite/Hero/Hero";
import ChartWrapper from "../../Components/PublicSite/ChartWrapper/ChartWrapper";
import ChartMapControls from "../../Components/PublicSite/ChartMapControls/ChartMapControls";
import LearnAboutDropdown from "../../Components/PublicSite/LearnAboutDropdown/LearnAboutDropdown";

import useContentful from "../../Lib/Hooks/useContentful";
import { getMapOrChartData, getAPIMethodByMapChartName } from "../../Lib/Helpers/MapChartHelpers";
import { useAppContext } from "../../Lib/UserContext";
import { ENUMS } from "../../Lib/Enums";

export default function DataExplorer() {
  const { stateLocation } = useAppContext();
  const {
    getSiteContent,
    getChartContent,
    getDropdownMenuOptions
  } = useContentful();

  const [siteContent, setSiteContent] = useState({});
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectionHook, setSelectionHook] = useState("");
  const [selectionBi, setSelectionBi] = useState("");
  const [listOfCharts, setListOfCharts] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [dashboardData, setDashboardData] = useState({});
  const [chartData, setChartData] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [statePolicyYear, setStatePolicyYear] = useState(2023);
  const [chartNameToChartInfo, setChartNameToChartInfo] = useState({});
  const [chartName, setSelectedChartName] = useState("");

  // eslint-disable-next-line no-unused-vars
  const [statePolicyStateSelection, setStatePolicyStateSelection] = useState([
    { name: 'U.S.', stateLocation: 'U.S.' },
    {
      name: 'Texas',
      stateLocation: 'TX'
    }
  ]);

  const getChartNameToChartInfo = useCallback(() => {
      const nameToFiltersMap = {};

      if (!dashboardData) {
        return null;
      }

      for (const key of Object.keys(dashboardData)) {
        for (const mapName of Object.keys(dashboardData[key])) {
          nameToFiltersMap[mapName] = dashboardData[key][mapName];
        }
      }

      return nameToFiltersMap;
  }, [dashboardData]);

  const getSiteHeaderTitle = (header) => {
    if (!header) {
      return (<Fragment></Fragment>)
    }

    const headerElementStr = header
      .replace("\\", "<span className='site-header-title title highlight'>")
      .replace("\\", "</span>");
    return (
      <div
        className="site-header-title title"
        dangerouslySetInnerHTML={{ __html: headerElementStr }}
      >
      </div>
    );
  };

  const stateFilterCallback = (item) => {
    var newArray = [...selectedFilters];
    for (let i = 0; i < newArray.length; i++) {
      if (
        newArray[i].name === item.name &&
        newArray[i].filter === item.filter
      ) {
        newArray[i] = item
      }
    }
    setSelectedFilters(newArray);
  };

  useEffect(() => {
    (async () => {
      const response = await getSiteContent(ENUMS.ROUTES.DATA_EXPLORER.CID);
      setSiteContent(response);
    })();
  }, [getSiteContent]);

  useEffect(() => {
    (
      async () => {
        const response = await getDropdownMenuOptions(ENUMS.ROUTES.DATA_EXPLORER.CID);
        setDropdownOptions(response);
        setSelectedCategory(response[0].value);
      }
    )();
  }, [getDropdownMenuOptions]);

  useEffect(() => {
    const dropdownOption = dropdownOptions.filter((opt) => opt.value === selectedCategory)[0] || {};

    setSelectedChartName(dropdownOption.chartMapName || "");
    setSelectionHook(dropdownOption.selectionHook || "");
    setSelectionBi(dropdownOption.selectionBi || "");

    (async () => {
      const response = await getChartContent(ENUMS.ROUTES.DATA_EXPLORER.CID);
      const charts = response.filter(({ chartSelectionCategory }) =>
        chartSelectionCategory === selectedCategory
      );

      setChartData(null);
      setListOfCharts(charts);
    })();
  }, [getChartContent, selectedCategory, dropdownOptions]);

  const callAPI = (method, filters, data, apiMethodToCategory) => {
    const payload = {
      filters,
      stateLocation
    }

    if (chartName === 'StatePolicy') {
      payload.statePolicyStateSelection = statePolicyStateSelection;
    }

    API.post("lighthouse-data-initiative", method, {
      body: payload
    }).then((response) => {
      data[apiMethodToCategory[method]] = response;
      setChartData(data);
    }).catch((error) => {
      console.log("Error on api call", error);
    });
  };

  const throttleCallAPI = throttle(callAPI, 1000);

  useEffect(() => {
    const { defaultFilterArray, dashboardData } = getMapOrChartData(stateLocation);

    setDashboardData(dashboardData);
    setSelectedFilters(defaultFilterArray);
    setChartNameToChartInfo(getChartNameToChartInfo());
  }, [stateLocation, getChartNameToChartInfo]);

  useEffect(() => {
    const apiMethodToCategory = {};
    const data = {};

    const apiMethods = uniq(listOfCharts.map((chart) => {
      const methodName = getAPIMethodByMapChartName(chart.chartCategory, false, stateLocation);
      apiMethodToCategory[methodName] = chart.chartCategory;
      return methodName;
    }));

    apiMethods.forEach((method) => {
      throttleCallAPI(method, selectedFilters, data, apiMethodToCategory);
    });
    // eslint-disable-next-line
  }, [listOfCharts, selectedFilters], [listOfCharts]);

  return (
    <Layout>
      <div className="data-explorer">
        <Hero
          title={getSiteHeaderTitle(siteContent.header)}
          body={siteContent.headerBody}
          button={siteContent.headerButton}
          buttonLink={siteContent.headerButtonLink}
        />
        <LearnAboutDropdown
          menuItems={dropdownOptions}
          selectedValue={selectedCategory}
          setSelectedValue={setSelectedCategory}
        />
        <div className="site-body">
          {selectionHook && (
            <div className="site-section">
              <div className="site-header">
                {getSiteHeaderTitle(selectionHook)}
              </div>
              <div className="site-section-body">
                  <div>
                    <Markdown>
                    {selectionBi}
                    </Markdown>
                  </div>
              </div>
            </div>
          )}
          <div>
            {listOfCharts.length && chartData?.[chartName] && (
              <ChartMapControls
                className="global-filter"
                isMap={false}
                mapName={chartName}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                mapNameToMapInfo={chartNameToChartInfo}
                mapData={chartData}
                stateFilterCallback={stateFilterCallback}
                statePolicyYear={statePolicyYear}
                setStatePolicyYear={setStatePolicyYear}
              />
            )}
            <div className="chart-list">
              {
                listOfCharts.length && chartData?.[chartName] &&
                  listOfCharts.map((chart, key) => (
                    <ChartWrapper
                      key={key}
                      chartData={chartData}
                      chartCategory={chart.chartCategory}
                      chartId={chart.chartId}
                      chartSource={chart.chartSource}
                      chartTitle={chart.chartTitle}
                      chartInsight={chart.chartInsight}
                      subchartInsight={chart.subchartInsight}
                    />
                  ))
              }
            </div>
          </div>
        </div>
        <div className="site-section">
          <LoginCTABlock
            ctaTitle={siteContent.ctaTitle}
            ctaBody={siteContent.ctaBody}
            ctaButton={siteContent.ctaButton}
            ctaButtonLink={siteContent.ctaButtonLink}
          />
        </div>
      </div>
    </Layout>
  );
}
