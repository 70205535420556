import React, { useEffect, useState, Fragment } from "react";
import { API } from "aws-amplify";
import uniq from "lodash/uniq";
import throttle from "lodash/throttle";

import Layout from "./Layout";
import Hero from "../../Components/PublicSite/Hero/Hero";
import MediaWrapper from "../../Components/PublicSite/MediaWrapper/MediaWrapper";
import CustomTable from "../../Components/PublicSite/CustomTable/CustomTable";
import TimelineWrapper from "../../Components/PublicSite/TimelineWrapper/TimelineWrapper";
import ChartWrapper from "../../Components/PublicSite/ChartWrapper/ChartWrapper";

import { getMapOrChartData, getAPIMethodByMapChartName } from "../../Lib/Helpers/MapChartHelpers";
import { useAppContext } from "../../Lib/UserContext";
import useContentful from "../../Lib/Hooks/useContentful";
import { ENUMS } from "../../Lib/Enums";

export default function StatePolicy() {
  const { getSiteContent, getTableContent, getTimelineContent, getChartContent } = useContentful();
  const { stateLocation } = useAppContext();
  const [siteContent, setSiteContent] = useState({});
  const [tableItems, setTableItems] = useState([]);
  const [timelineItems, setTimelineItems] = useState([]);
  const [charts, setCharts] = useState([]);
  const [chartData, setChartData] = useState(null);

  const getSiteHeaderTitle = (header) => {
    if (!header) {
      return (<Fragment></Fragment>)
    }

    const headerElementStr = header
      .replace("\\", "<span className='site-header-title title highlight'>")
      .replace("\\", "</span>");
    return (
      <div
        className="site-header-title title"
        dangerouslySetInnerHTML={{ __html: headerElementStr }}
      >
      </div>
    );
  };

  const callAPI = (method, defaultFilterArray, data, apiMethodToCategory) => {
    API.post("lighthouse-data-initiative", method, {
      body: {
        filters: defaultFilterArray,
        stateLocation
      }
    }).then((response) => {
      data[apiMethodToCategory[method]] = response;
      setChartData(data);
    }).catch((error) => {
      console.log("Error on api call", error);
    });
  };

  const throttleCallAPI = throttle(callAPI, 1000);

  useEffect(() => {
    const { defaultFilterArray } = getMapOrChartData(stateLocation);
    const apiMethodToCategory = {};
    const data = {};

    const apiMethods = uniq(charts.map((chart) => {
      const methodName = getAPIMethodByMapChartName(chart.chartCategory, false, stateLocation);
      apiMethodToCategory[methodName] = chart.chartCategory;
      return methodName;
    }));

    apiMethods.forEach((method) => {
      throttleCallAPI(method, defaultFilterArray, data, apiMethodToCategory);
    });
  }, [charts, stateLocation, throttleCallAPI]);

  useEffect(() => {
    (async () => {
      const pageCid = ENUMS.ROUTES.POLICY.CID;
      const siteContentRes = await getSiteContent(pageCid);
      const tableItemsRes = await getTableContent(pageCid);
      const timelineItemsRes = await getTimelineContent(pageCid);
      const chartsRes = await getChartContent(pageCid);

      setSiteContent(siteContentRes);
      setTableItems(tableItemsRes);
      setTimelineItems(timelineItemsRes);
      setCharts(chartsRes);
    })();
  }, [getSiteContent, getTableContent, getTimelineContent, getChartContent]);

  return (
    <Layout>
      <div className="policy">
        <Hero
          title={getSiteHeaderTitle(siteContent.header)}
          body={siteContent.headerBody}
          button={siteContent.headerButton}
          buttonLink={siteContent.headerButtonLink}
        />
        <MediaWrapper
          url={siteContent.headerMedia?.url}
          contentType={siteContent.headerMedia?.contentType}
          title={siteContent.headerMedia?.title}
        />
        <div className="site-body">
          <div className="site-section">
              <div className="site-header">
                {getSiteHeaderTitle(siteContent.introTitle)}
              </div>
              {
                siteContent.introBodyColumns
                ?  (
                  <div className="site-section-body-group column-group">
                    {
                        siteContent.introBodyColumns.split("\n")?.map((column, key) => {
                          return column.trim()
                            ? (
                              <div key={key} className="column-group-item">
                                {column}
                              </div>
                            )
                            : <Fragment key={key}></Fragment>
                        })
                    }
                  </div>
                )
                : <Fragment></Fragment>
              }
              <div className="site-section-body-group">
                {siteContent.introBodySingle}
              </div>
              <CustomTable data={tableItems} />
          </div>
          <div className="site-section">
            <TimelineWrapper
              timelineTitle={siteContent.timelineTitle}
              timelineItems={timelineItems}
              charts={
                charts.map((chart, key) => (
                  <ChartWrapper
                    key={key}
                    chartData={chartData}
                    chartCategory={chart.chartCategory}
                    chartId={chart.chartId}
                    chartSource={chart.chartSource}
                    chartTitle={chart.chartTitle}
                    chartInsight={chart.chartInsight}
                    subchartInsight={chart.subchartInsight}
                  />
                ))
              }
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}
